import React, { Component } from "react"
import styled from "styled-components"

const Container = styled.div`
    display: ${props => (props.visible ? "flex" : "none")};
    align-items: center !important;
    align-content: center !important;
    justify-content: center !important;
    border-radius: 50%;
    padding: ${props => props.padding}rem;
    width: ${props => props.width}rem !important;
    height: ${props => props.height}rem !important;
    min-width: ${props => props.width}rem !important;
    min-height: ${props => props.height}rem !important;
    max-width: ${props => props.height}rem !important;
    max-height: ${props => props.height}rem !important;
    background-color: ${props => props.backgroundColor};
    border: 2px solid ${props => props.borderColor};

    &:hover {
        background-color: ${props => props.backgroundColorOnHover};
        border: 2px solid ${props => props.borderColorOnHover};
        cursor: pointer;
    }
    path {
        fill: ${props => props.iconColor};
        -webkit-transition: background-color 300ms linear;
        -ms-transition: background-color 300ms linear;
        transition: background-color 300ms linear;
    }
    &:hover path {
        fill: ${props => props.iconColorOnHover};
    }
`

export default class CalendarIcon extends Component {
    render() {
        const { ...props } = this.props
        const Padding = 0.5
        const Width = 2
        const Height = 2
        const Visible = false === this.props.visible ? false : true

        const IconColor = this.props.iconColor
            ? this.props.iconColor
            : "tranparent"
        const IconColorOnHover = this.props.iconColorOnHover
            ? this.props.iconColorOnHover
            : "tranparent"
        const BackgroundColor = this.props.backgroundColor
            ? this.props.backgroundColor
            : "tranparent"
        const BackgroundColorOnHover = this.props.backgroundColorOnHover
            ? this.props.backgroundColorOnHover
            : "tranparent"
        const BorderColor = this.props.borderColor
            ? this.props.borderColor
            : "tranparent"
        const BorderColorOnHover = this.props.borderColorOnHover
            ? this.props.borderColorOnHover
            : "tranparent"

        return (
            <Container
                onClick={this.props.onClick ? this.props.onClick : undefined}
                width={Width}
                height={Height}
                padding={Padding}
                visible={Visible}
                iconColor={IconColor}
                iconColorOnHover={IconColorOnHover}
                backgroundColor={BackgroundColor}
                backgroundColorOnHover={BackgroundColorOnHover}
                borderColor={BorderColor}
                borderColorOnHover={BorderColorOnHover}
                {...props}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    x="0px"
                    y="0px"
                    width="100%"
                    height="100%"
                    viewBox="0 0 32 32"
                >
                    <path d="M29.334,3H25V1c0-0.553-0.447-1-1-1s-1,0.447-1,1v2h-6V1c0-0.553-0.448-1-1-1s-1,0.447-1,1v2H9V1 c0-0.553-0.448-1-1-1S7,0.447,7,1v2H2.667C1.194,3,0,4.193,0,5.666v23.667C0,30.806,1.194,32,2.667,32h26.667 C30.807,32,32,30.806,32,29.333V5.666C32,4.193,30.807,3,29.334,3z M30,29.333C30,29.701,29.701,30,29.334,30H2.667 C2.299,30,2,29.701,2,29.333V5.666C2,5.299,2.299,5,2.667,5H7v2c0,0.553,0.448,1,1,1s1-0.447,1-1V5h6v2c0,0.553,0.448,1,1,1 s1-0.447,1-1V5h6v2c0,0.553,0.447,1,1,1s1-0.447,1-1V5h4.334C29.701,5,30,5.299,30,5.666V29.333z" />
                    <rect x="7" y="12" width="4" height="3" />
                    <rect x="7" y="17" width="4" height="3" />
                    <rect x="7" y="22" width="4" height="3" />
                    <rect x="14" y="22" width="4" height="3" />
                    <rect x="14" y="17" width="4" height="3" />
                    <rect x="14" y="12" width="4" height="3" />
                    <rect x="21" y="22" width="4" height="3" />
                    <rect x="21" y="17" width="4" height="3" />
                    <rect x="21" y="12" width="4" height="3" />
                </svg>
            </Container>
        )
    }
}
