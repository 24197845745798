import Config from "./Config"

export const ENDPOINTS = {
    login: Config.url + "/api/login",
    register: Config.url + "/api/register",
    forgotPassword: Config.url + "/api/resetPassword",
    setNewPassword: Config.url + "/api/setNewPassword",
    checkMailAvailability: Config.url + "/api/register/checkAvailability",
    checkCompanyNameAvailability: Config.url + "/api/register/checkCompanyAvailability",
    getNodes: Config.url + "/api/timeline/nodes",
    getScore: Config.url + "/api/timeline/getScore",
    updateActivity: Config.url + "/api/activity/update",
    hideNode: Config.url + "/api/node/setHidden",
    uploadFile: Config.url + "/api/file/upload",
    getFile: Config.url + "/api/file/display",
    updateMEP: Config.url + "/api/mep/update",
    getUserData: Config.url + "/api/user/details",
    getFAQ: Config.url + "/api/faq/getAll"
};
