import { Colors } from "../../Theme"
import React, { Component } from "react"
import styled from "styled-components"

const InputField = styled.textarea`
    width: 100%;
    margin: 1rem 0.25rem;    
    font-size: 1rem;
    line-height: 2rem;
    flex: 1;
    border-radius: 0.5rem;
    padding: 0.5rem;
    outline: none;
    color: ${props => props.textColor} !important;
    -webkit-text-fill-color: ${props => props.textColor} !important;
    -webkit-box-shadow: 0 0 0 100px ${props => props.backgroundColor} inset !important;
    border: 1px solid ${props => props.borderColor} !important;
    
    &:hover,
    &:focus,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        border-bottom: 1px solid ${Colors.blue};
        -webkit-text-fill-color: ${props => props.textColor};
    }
    
    &::placeholder {
        font-style: italic;
    }
`;

export default class TextAreaField extends Component {
    render() {
        const BackgroundColor = this.props.backgroundColor
            ? this.props.backgroundColor
            : "transparent"

        if(this.props.visible) {
            return (
            <InputField
              type={this.props.type}
              placeholder={this.props.placeholder ? this.props.placeholder : this.props.label}
              step={this.props.type === "number" ? "any" : ""}
              name={this.props.name}
              value={ this.props.value ? this.props.value : "" }
              textColor={this.props.textColor}
              borderColor={this.props.error ? Colors.red : Colors.gray_lighter}
              backgroundColor={BackgroundColor}
              onChange={(e) => this.props.valueOfTextfieldUpdated(e.target.value)}
            /> );
        } else {
            return null;
        }
    }
}

TextAreaField.defaultProps = {
    type: 'text',
    value: '',
    visible: true,
    textColor: Colors.text,
};
