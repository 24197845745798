import { ENDPOINTS } from "./Endpoints"
import { GETFILE } from "./Requests"

export default async function GetFileFromPIT(FileId) {
    try {
        return GETFILE(ENDPOINTS.getFile + "?id=" + FileId)
    } catch (error) {
        console.error(error)
    }
}
