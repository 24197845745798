import Axios from "axios"
import { get } from "idb-keyval"

export function GET(url) {
    return Axios.get(url)
        .then(res => {
            return res
        })
        .catch(res => {
            return res
        })
}

export async function POST(url, body, contentType = "application/json") {
    const headers = {
        headers: {
            "Content-Type": contentType,
            Authorization: "Bearer " + (await get("pit_token"))
        }
    }

    return Axios.post(url, body, headers)
        .then(res => {
            return res
        })
        .catch(res => {
            return res
        })
}

export async function UPLOAD(url, body) {
    return POST(url, body, "multipart/form-data")
}

export async function GETFILE(url) {
    return Axios.get(url, {
        responseType: "arraybuffer",
        headers: {
            Authorization: "Bearer " + (await get("pit_token"))
        }
    }).then(response => {
        let image = btoa(
            new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
            )
        )
        return `data:${response.headers[
            "content-type"
        ].toLowerCase()};base64,${image}`
    })
}
