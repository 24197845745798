import "react-datepicker/dist/react-datepicker.css"
import {Colors, Settings} from "./Theme"
import {get, set} from "idb-keyval"
import Activity from "./components/timeline/Activity"
import Auth from "./components/authentication/Auth"
import DatePicker from "react-datepicker"
import FilterButtons from "./components/timeline/FilterButtons"
import IdleTimer from "react-idle-timer"
import Menu from "./components/layout/Menu"
import MepItems from "./components/timeline/MepItems"
import Navigation from "./components/navigation/Navigation"
import PitNodes from "./components/pit/GetNodes"
import PitScore from "./components/pit/GetScore"
import React, {Component} from "react"
import Scorebar from "./components/Score/Scorebar"
import Sidebar from "react-sidebar"
import styled from "styled-components"
import Timeline from "./components/timeline/Timeline"
import TipActivities from "./components/timeline/TipActivities"
import GetUserData from "./components/pit/GetUserData";
import TimelinePicker from "./components/layout/TimelinePicker";
import Login from "./components/authentication/Login";
import SameDate from "./components/_helpers/Date/SameDate";
import FAQ from "./components/timeline/FAQ";
import { connect } from 'react-redux';
import {Modal} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Refresh } from 'mdi-material-ui';


const Version = '21.05.003';

const Columns = styled.div`
    margin-top: 4rem;
    width: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
`;

const Row = styled.div`
    height: calc(100vh - 65px);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
`;

const MenuColumn = styled.div`
    background: ${Colors.white};
    box-shadow: ${Settings.box_shadow};
    display: ${props => (props.visible ? "flex" : "none")};
    flex-direction: column;
    flex: 0 0 25%;
    height: calc(100vh - 65px);
    overflow:auto;
    max-width: 25%;
    min-height: 1px;
    position: relative;
    width: 25%;

    @media only screen and (max-width: 600px) {
        left: 0;
        max-width: 90%;
        position: absolute;
        top: 0;
        width: 90%;
        z-index: 2;
    }
`;

const ContentColumn = styled.div`
    display: flex;
    background: ${Colors.background};
    flex-direction: column;
    height: 100%;
    width: 100%;
    min-height: 1px;
    overflow-y: scroll !important;
    position: relative;
    > div {
        width: 100%;
    }

    @media only screen and (max-width: 600px) {
        width: 100%;
        flex: 0 0 100%;
        left: 0;
        
    }
`;

const UpdateWindow = styled.div`
    position: fixed;
    width: 50%;
    // height: 50%;
    min-width: 200px;
		background: rgba(255,255,255,0.8);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    > p {
        margin: 2rem;
    }
    > button {
        margin: 2rem;
    }
`;

class App extends Component {
	constructor(props) {
		super(props)

		this.state = {
			appliedActivityFilter: "Open taken",
			authenticated: false,
			groups: false,
			timeLines: false,
			loading: false,
			loginError: false,
			menuVisible: false,
			mepItems: [],
			faqItems: [],
			navigateBack: false,
			nodes: [],
			score: 0,
			selectedActivity: false,
			selectedDate: new Date(),
			selectedNode: false,
			selectedTimeline: 1,
			selectedTimelineName: '',
			selectedGroupName: '',
			showDatepicker: false,
			showTimelinepicker: false,
			sidebarOpen: false,
			fullName: "",
			view: "timeline",
			nodeDisplayDate: '',
		};

		this.getNodesDate = this.getNodesDate.bind(this);
		this.onIdle = this._onIdle.bind(this);
		this.setSelectedDate = this.setSelectedDate.bind(this);

		this.tips = [];
		this.allNodes = [];
		this.activityFilters = [
			{
				name: "Open taken",
				properties: [
					{nameOfProperty: "completed", valueOfProperty: false}
				],
				action: this.showOpenActivities
			},
			{
				name: "Alle taken",
				properties: [],
				action: this.showAllActivities
			}
		];
	};

	async getNodesDate (date) {
		const dayStart = await (get("pit_timeline_daystart"));
		const hours = dayStart ? dayStart.substring(0, 2) : "00";
		const minutes = dayStart ? dayStart.substring(3, 5) : "00";
		const MS_PER_MINUTE = 60000;
		const MS_PER_HOUR = 60000 * 60;
		return new Date(date - minutes * MS_PER_MINUTE - hours * MS_PER_HOUR);
	};

	async _onIdle(e) {
		if (
			this.state.view !== "faq" ||
			!SameDate(new Date(), await this.getNodesDate(this.state.selectedDate)) ||
			this.state.appliedActivityFilter !== "Open taken"
		) {
			this.updateNodes();
			this.changeView("timeline");
		}
	};

	async componentDidMount() {
		let isAuthenticated = await Auth.isAuthenticated();
		this.setState({authenticated: isAuthenticated});
		if (isAuthenticated === true) {
			this.updateNodes();
		}

		let groups = await get("pit_groups");
		if (typeof groups === "string") {
			let ParsedGroups = JSON.parse(groups);
			ParsedGroups.sort((a, b) =>
				a.name > b.name ? 1 : b.name > a.name ? -1 : 0
			);
			this.setState({groups: ParsedGroups});
		}
		let timeLines = await get("pit_timelines");
		if (typeof timeLines === "string") {
			let ParsedTimeLines = JSON.parse(timeLines);
			this.setState({timeLines: ParsedTimeLines});
		}

		const SelectedTimelineId = await get("pit_timeline_id");
		const SelectedTimelineName = await get("pit_timeline_name");
		const SelectedGroupName = await get("pit_group_name");
		const FullName = await get("pit_fullname");

		if (typeof SelectedTimelineId === "number") {
			this.setState({selectedTimelineId: SelectedTimelineId});
		}

		if (typeof SelectedTimelineName === "string") {
			this.setState({selectedTimelineName: SelectedTimelineName});
		}

		if (typeof SelectedGroupName === "string") {
			this.setState({selectedGroupName: SelectedGroupName});
		}

		if (typeof FullName === "string") {
			this.setState({fullName: FullName});
		}
	};

	async setSelectedDate(date) {
		let selectedDate = date ? date : new Date();
		if (typeof date === "string") {
			selectedDate = new Date(JSON.parse(date))
		}

		this.setState({
			selectedDate: selectedDate
		});

		this.closeMenu()
	};

	changeTimeline = (TimelineId, TimelineName, GroupName, DayStart) => {
		set("pit_timeline_id", TimelineId);
		set("pit_timeline_name", TimelineName);
		set("pit_group_name", GroupName);
		set("pit_timeline_daystart", DayStart);
		this.setState({selectedTimelineId: TimelineId, selectedTimelineName: TimelineName, selectedGroupName: GroupName});
		this.updateNodes();
		this.closeMenu();
		this.hideTimelinepicker();
	};

	changeView = ViewName => {
		this.setState({view: ViewName});
		this.closeSidebar();
		this.closeMenu();
	};

	checkUserData = () => {
		GetUserData()
			.then(res => {

				let Groups = false
				if (res.data.result.groups) {
					Groups = JSON.stringify(res.data.result.groups)
					set("pit_groups", Groups)
				}
				let TimeLines = false
				if (res.data.result.timelines) {
					TimeLines = JSON.stringify(res.data.result.timeLines)
					set("pit_timelines", TimeLines)
				}

				set("pit_fullname", (res.data.result.fullname ? res.data.result.fullname : ""))

				this.setState({
					fullName: res.data.result.fullname ? res.data.result.fullname : "",
					groups: JSON.parse(Groups)
				})
			})
	};

	filterTimeline = (FilterName) => {
		this.setState({appliedActivityFilter: FilterName});

		const Filters = this.activityFilters.filter((Filter, index) => {
			return Filter.name === FilterName
		});

		let FilteredNodes = this.allNodes;

		try {
			FilteredNodes = FilteredNodes.map((Node, index) => {
				Node.visible = true;
				Node.visibleActivities = 0;

				const TimelineIsFiltered =
					typeof Filters[0] === "object" &&
					Filters[0].properties.length > 0;

				Node.activities = Node.activities.map((Activity, index) => {
					Activity.visible = true;
					if (TimelineIsFiltered) {
						const ChoosenFilter = Filters[0];
						Activity.visible = false;
						ChoosenFilter.properties.forEach(Filter => {
							if (
								Activity[Filter.nameOfProperty] ===
								Filter.valueOfProperty
							) {
								Activity.visible = true;
								Node.visibleActivities =
									Node.visibleActivities + 1
							}
						})
					}

					return Activity;
				});

				if (
					TimelineIsFiltered === true &&
					Node.visibleActivities === 0 &&
					Node.overdue === true
				) {
					Node.visible = false
				}

				return Node
			})
		} catch (err) {
			console.error(err.message);
		}

		this.setState({nodes: FilteredNodes});
	};

	showAllActivities = () => {
		this.filterTimeline("Alle taken")
	};

	showOpenActivities = () => {
		this.filterTimeline("Open taken")
	};

	selectActivity = Activity => {
		if (Activity.id !== undefined) {
			this.setState({
				selectedActivity: Activity
			});
			this.toggleSidebar();
		} else {
			this.setState({
				selectedActivity: {}
			});
		}
	};

	updateServiceWorker = () => {
		const registrationWaiting = this.props.serviceWorkerRegistration.waiting;

		if (registrationWaiting) {
			registrationWaiting.postMessage({type: 'SKIP_WAITING'});

			registrationWaiting.addEventListener('statechange', e => {
				if (e.target.state === 'activated') {
					window.location.reload();
				}
			});
		} else {
			window.location.reload();
		}
	};

	updateNode = updateNode => {
		let { nodes } = this.state;
		nodes.map(node => {
			if(node.id === updateNode.id) {
				return updateNode;
			}
			return node;
		});
		this.setState({nodes})
	};

	updateNodes = (date) => {
		if (!date) {
			date = this.getNodesDate(this.state.selectedDate)
				.then( date => {
					this.updateNodes(date);
				});
			return;
		}

		this.setState({nodeDisplayDate: date});
		PitNodes(date)
			.then(res => {
				if (res.data.result.mepItems) {
					this.setState({mepItems: res.data.result.mepItems})
				}

				if (res.data.result.nodes) {
					const NodesFromResponse = res.data.result.nodes;

					var Nodes = [];
					var NodeID = 1;
					var ActivityID = 1;
					const Now = new Date();
					for (let k in NodesFromResponse) {
						let Node = NodesFromResponse[k];
						const DateDue = new Date(Node.due);

						Node.overdue = DateDue < Now;

						let AmountCompleted = 0;
						let AmountOverdue = 0;
						let AmountEscalated = 0;
						for (let k in Node.activities) {
							const ActivityFromResponse = Node.activities[k];

							if (ActivityFromResponse.escalated) {
								AmountEscalated = AmountEscalated + 1;
							} else if (
								false === ActivityFromResponse.completed &&
								DateDue < Now
							) {
								AmountOverdue = AmountOverdue + 1;
							} else if (ActivityFromResponse.completed) {
								AmountCompleted = AmountCompleted + 1;
							}

							ActivityFromResponse.checkboxStatus = "open";
							if (ActivityFromResponse.escalated) {
								ActivityFromResponse.checkboxStatus = "escalated";
							} else if (false === ActivityFromResponse.completed && DateDue < Now) {
								ActivityFromResponse.checkboxStatus = "overdue";
							} else if (ActivityFromResponse.completed) {
								ActivityFromResponse.checkboxStatus = "checked";
							}

							if (Node.isTip) {
								// Put Activity in TIPS list.
								ActivityFromResponse.id = ActivityID;
								this.tips.push(ActivityFromResponse);
							}
							ActivityID++
						}

						if (Node.isTip) {
							continue;
						}

						Node.amountToDo = Node.activities.length - AmountCompleted;
						Node.percentageCompleted = Math.round(
							(100 / Node.activities.length) * AmountCompleted
						);
						Node.percentageOverdue = Math.round(
							(100 / Node.activities.length) * AmountOverdue
						);
						Node.percentageEscalated = Math.round(
							(100 / Node.activities.length) * AmountEscalated
						);

						// Node.id = NodeID;
						Node.number = NodeID;
						Nodes.push(Node);
						NodeID++;
					}

					this.setState({sidebarOpen: false});

					this.allNodes = Nodes;
					this.filterTimeline(this.state.appliedActivityFilter);
				}
			})
			.catch(res => {
				this.setState({
					nodes: []
				})
			});

		PitScore(date)
			.then(res => {
				let Score = 0;
				if (res.data.result.score !== undefined) {
					Score = Math.round(res.data.result.score);
				}
				this.setState({score: Score});
			})
			.catch(res => {
				this.setState({score: 0});
			})
	};

	goToDate = date => {
		this.updateNodes();
	};

	login = async (Groups) => {
		this.updateNodes();
		let parsedTimeLines = null;
		const timeLines = await get('pit_timelines');
		if (typeof timeLines === "string") {
			parsedTimeLines = JSON.parse(timeLines);
			this.setState({timeLines: parsedTimeLines});
		}
		this.setState({
			authenticated: await Auth.isAuthenticated(),
			selectedTimelineId: await get("pit_timeline_id"),
			selectedTimelineName: await get("pit_timeline_name"),
			selectedGroupName: await get("pit_group_name"),
			fullName: await get("pit_fullname"),
			timeLines: parsedTimeLines,
			groups: JSON.parse(Groups)
		});

	};

	toggleMenu = () => {
		this.checkUserData()
		const newState = !this.state.menuVisible
		this.setState({menuVisible: newState})
	};

	toggleTimelinePicker = () => {
		this.checkUserData()
		const newState = !this.state.showTimelinepicker
		this.setState({showTimelinepicker: newState})
	};

	closeMenu = () => {
		this.setState({menuVisible: false});
	};

	toggleSidebar = () => {
		this.setState({sidebarOpen: !this.state.sidebarOpen});
	};

	closeSidebar = () => {
		this.setState({sidebarOpen: false});
	};

	showDatepicker = () => {
		this.setState({showDatepicker: true})
	};

	showTimelinepicker = () => {
		this.setState({showTimelinepicker: true})
	};

	hideDatepicker = () => {
		this.setState({showDatepicker: false})
	};

	hideTimelinepicker = () => {
		this.setState({showTimelinepicker: false})
	};

	dateInDatepickerSelected = (date) => {
		if (SameDate(date, new Date()))
			date = new Date();

		this.setState({
			selectedDate: date
		});

		this.hideDatepicker();
		this.updateNodes(date);
	};

	logout = () => {
		Auth.logout(async () => {
			this.setState({
				authenticated: await Auth.isAuthenticated()
			});
		})
	};

	render() {
		const view = this.state.view;
		if (this.state.authenticated === true) {
			return (
				<React.Fragment>
					{this.props.isServiceWorkerUpdated && (
						<Modal open={true}>
							<UpdateWindow>
								<p>Update beschikbaar</p>
								<Button size="large"
								        startIcon={<Refresh />}
								        variant="contained"
								        color="primary"
								        onClick={() => this.updateServiceWorker()}>Update</Button>
							</UpdateWindow>
						</Modal>
					)}

					<Sidebar
						sidebar={
							<Activity
								activity={this.state.selectedActivity}
								selectActivity={this.selectActivity}
								updateNodes={this.updateNodes}
								closeSidebar={this.toggleSidebar}
							/>
						}
						open={this.state.sidebarOpen}
						onSetOpen={this.toggleSidebar}
						styles={{
							sidebar: {background: Colors.white, width: "66%"},
							overlay: {
								opacity: 0.66,
								backgroundColor: "rgba(0,0,0,.66)"
							}
						}}
						pullRight={true}
						touch={true}
					>
						{this.state.showDatepicker && (
							<DatePicker
								selected={this.state.selectedDate}
								onChange={this.dateInDatepickerSelected}
								onClick={this.dateInDatepickerSelected}
								onClickOutside={this.hideDatepicker}
								withPortal
								inline
							/>
						)}

						<IdleTimer
							ref={ref => {
								this.idleTimer = ref
							}}
							element={document}
							onActive={this.onActive}
							onIdle={this.onIdle}
							onAction={this.onAction}
							debounce={250}
							timeout={20000} // 20000 milliseconds = 20 seconds
						/>
						<Navigation
							updateNodes={this.updateNodes}
							logout={this.logout}
							toggleMenu={this.toggleMenu}
							toggleTimelinePicker={this.toggleTimelinePicker}
							selectedTimelineName={this.state.selectedGroupName + " - " + this.state.selectedTimelineName}
						/>
						<TimelinePicker
							visible={this.state.showTimelinepicker}
							groups={this.state.groups}
							timeLines={this.state.timeLines}
							changeTimeline={this.changeTimeline}
							close={this.hideTimelinepicker}
							selectedTimelineId={
								this.state.selectedTimelineId
							}
						/>

						<Columns>
							<Row>
								<MenuColumn
									visible={this.state.menuVisible}
								>
									<Menu
										currentView={this.state.view}
										groups={this.state.groups}
										timeLines={this.state.timeLines}
										selectedTimelineId={
											this.state.selectedTimelineId
										}
										changeTimeline={this.changeTimeline}
										logoutFunction={this.logout}
										changeViewFunction={this.changeView}
										showDatepicker={this.showDatepicker}
										hideDatepicker={this.hideDatepicker}
										datepickerVisible={
											this.state.showDatepicker
										}
										selectedDate={
											this.state.selectedDate
										}
										version={Version}
									/>
								</MenuColumn>
								<ContentColumn>
									{view === "mep" && (
										<MepItems
											items={this.state.mepItems}
											timeline={this.state.selectedTimelineId}
											date={this.state.selectedDate}
										/>
									)}
									{view === "faq" && (
										<FAQ
											faqs={this.state.faqItems}
											timeline={this.state.selectedTimelineId}
											date={this.state.selectedDate}
										/>
									)}
									{(view === "timeline" || view === "tips") && (
										<div>
											<Scorebar
												score={this.state.score}
												date={
													this.state.selectedDate
												}
												selectedDate={
													this.state.selectedDate
												}
											/>
											<FilterButtons
												displayDate={this.state.nodeDisplayDate}
												activityFilters={
													this.activityFilters
												}
												appliedActivityFilter={
													this.state
														.appliedActivityFilter
												}
											/>
											{view === "tips" ? (
												<TipActivities
													tips={this.tips}
												/>
											) : (
												<Timeline
													date={
														this.state
															.selectedDate
													}
													nodes={this.state.nodes}
													updateNode = {(n) => this.updateNode(n)}
													goToDate={this.goToDate}
													updateNodes={
														this.updateNodes
													}
													selectActivity={
														this.selectActivity
													}
													changeView={
														this.changeView
													}
													setComponentToScrollTo={
														this
															.setComponentToScrollTo
													}
												/>
											)}
										</div>
									)}
								</ContentColumn>
							</Row>
						</Columns>
					</Sidebar>
				</React.Fragment>
			)
		} else {
			return (
				<Login loginSucces={(Groups) => this.login(Groups)}/>
			)
		}
	}
}

const mapStateToProps = (state) => {
	return {
		isServiceWorkerInitialized: state.serviceWorkerInitialized,
		isServiceWorkerUpdated: state.serviceWorkerUpdated,
		serviceWorkerRegistration: state.serviceWorkerRegistration
	};
};

export default connect(mapStateToProps)(App);
