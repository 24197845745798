import React, { Component } from "react";
import {Colors} from "../../../Theme";
import styled from "styled-components";


const Container = styled.div`
    display: flex;
    border-radius: 5px;
    margin: 0.25rem;
    padding: 1px;
    min-width: ${props => props.height}rem !important;
    max-width: ${props => props.height}rem !important;
    border: ${props => props.border ? "2px solid " + props.borderColor : null};

    &:hover {
        background-color: ${props => props.hover ? props.backgroundColorOnHover : ""};
        border: ${props => props.hover ? "2px solid " + props.borderColorOnHover : ""};
        cursor: ${props => props.onClick ? "pointer" : null};
    }
    path {
        fill: ${props => props.iconColor};
        -webkit-transition: background-color 300ms linear;
        -ms-transition: background-color 300ms linear;
        transition: background-color 300ms linear;
    }
    &:hover path {
        fill: ${props => props.iconColorOnHover};
    }
`

export default class InfoIcon extends Component {
    render() {
        return (
          <Container
            width={this.props.width}
            visible={this.props.visible}
            border={this.props.border}
            borderColor={this.props.borderColor}
            borderColorOnHover={this.props.borderColorOnHover}
            backgroundColorOnHover={this.props.backgroundColorOnHover}
            iconColor={this.props.iconColor}
            iconColorOnHover={this.props.iconColorOnHover}
            onClick={this.props.onClick}
          >
            <svg height={this.props.height + "pt"} width={this.props.width + "pt"} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="m277.332031 128c0 11.78125-9.550781 21.332031-21.332031 21.332031s-21.332031-9.550781-21.332031-21.332031 9.550781-21.332031 21.332031-21.332031 21.332031 9.550781 21.332031 21.332031zm0 0"/>
                <path d="m256 405.332031c-8.832031 0-16-7.167969-16-16v-165.332031h-21.332031c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h37.332031c8.832031 0 16 7.167969 16 16v181.332031c0 8.832031-7.167969 16-16 16zm0 0"/>
                <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"/>
                <path d="m304 405.332031h-96c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h96c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/>
            </svg>
          </Container>
        )
    }
}

InfoIcon.defaultProps = {
  width: 12,
  height: 12,
  visible: true,
  border: false,
  hover: false,
  iconColor: Colors.black,
  iconColorOnHover: Colors.black,
  borderColor: Colors.black,
  borderColorOnHover: Colors.black,
  backgroundColorOnHover: Colors.gray_lightest,
  onClick: undefined
};

