import React, { Component } from "react"
import { Colors } from "../../Theme";
import styled from "styled-components";
import {get} from "idb-keyval";
import PitFAQ from "../pit/GetFAQ";


const Container = styled.div`
    display: flex;
    padding-top: 1rem;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
`;

const Message = styled.div`
    padding: 1rem;
    color: ${Colors.gray};
    width: 100%;
`;

const ItemContainer = styled.div`
    margin: auto;
    margin-top:1rem;
    color: ${Colors.gray};
    width: 95%;
    border-bottom: 1px dashed #dadada;
`;

const Question = styled.div`
    padding: 1rem;
    font-weight: bold;
    color: ${Colors.black};
    width: 100%;
    display:flex;
    > div:first-child {
        flex-grow: 1;
    }
`;

const Answer = styled.div`
    padding: 1rem;
    width: 100%;
`;

const Arrow = styled.div`
    width: 25px;
`;

const Search = styled.div`
    width: 80%;
    margin: auto;
    display: flex;
`;

const SearchBox = styled.input`
    width: 100%;
    border-radius: 10px 0 0 10px;
    padding: 1rem;
    border: 1px solid #dadada;
    :focus {
        border: 1px solid #dadada;
        border-radius: 10px 0 0 10px;
        
    }
`;

const SearchIcon = styled.button`
    display:flex;
    align-items:center;
    flex-shrink:0;
    justify-content:center;
    width:64px;
    color:#ffffff;
    border:none;
    border-radius:0 8px 8px 0;
    background: ${Colors.highlight};
    font-size:16px;
`;


export default class FAQ extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            filteredItems: [],
            opened: null,
            searchValue: ""
        };
    };

    componentDidMount() {
        get("pit_timeline_id").then(id => {this.getFAQItems(id)});
    }

    getFAQItems(timelineId) {
        PitFAQ(timelineId)
          .then(res => {
            this.setState({
                items: res.data.result.faqs,
                filteredItems: res.data.result.faqs
            });
          });
    }

    setSelected = index => {
        if(this.state.opened === index)
            index = null;
        this.setState({opened: index})
    };

    onSearch = event => {
        let results = this.state.items.filter(function (e) {
            return e.question.includes(event.target.value);
        });

        this.setState({
            searchValue: event.target.value,
            filteredItems: results
        });
    };

    render() {
        if (this.state.items.length > 0) {
            return (
                <Container>
                    <Search>
                        <SearchBox
                            onChange={(e) => this.onSearch(e)}
                            value={this.state.searchValue}
                        />
                        <SearchIcon>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M23.111 20.058l-4.977-4.977c.965-1.52 1.523-3.322 1.523-5.251 0-5.42-4.409-9.83-9.829-9.83-5.42 0-9.828 4.41-9.828 9.83s4.408 9.83 9.829 9.83c1.834 0 3.552-.505 5.022-1.383l5.021 5.021c2.144 2.141 5.384-1.096 3.239-3.24zm-20.064-10.228c0-3.739 3.043-6.782 6.782-6.782s6.782 3.042 6.782 6.782-3.043 6.782-6.782 6.782-6.782-3.043-6.782-6.782zm2.01-1.764c1.984-4.599 8.664-4.066 9.922.749-2.534-2.974-6.993-3.294-9.922-.749z"/>
                            </svg>
                        </SearchIcon>
                    </Search>

                    {this.state.filteredItems.length === 0 &&
                        <Message>Geen overeenkomsten</Message>
                    }


                    {this.state.filteredItems.map((item, index) => (
                      <ItemContainer key={index}>
                          <Question onClick={() => this.setSelected(index)}>
                              <div>{item.question}</div>
                              {this.state.opened === index || this.state.filteredItems.length === 1 ?
                              <Arrow>
                                  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M23.245 20l-11.245-14.374-11.219 14.374-.781-.619 12-15.381 12 15.391-.755.609z" fill="red"/>
                                  </svg>
                              </Arrow> :
                              <Arrow>
                                  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M23.245 4l-11.245 14.374-11.219-14.374-.781.619 12 15.381 12-15.391-.755-.609z" fill="red"/>
                                  </svg>
                              </Arrow>}

                          </Question>
                          {(this.state.opened === index || this.state.filteredItems.length === 1) &&
                              <Answer id={'item-'+index}
                                      dangerouslySetInnerHTML={{
                                          __html: item.answer
                                      }}>

                              </Answer>}
                      </ItemContainer>
                    ))}
                </Container>
            )
        } else {
            return (
                <Container>
                    <Message>Geen FAQ toegevoegd.</Message>
                </Container>
            )
        }
    }
}
