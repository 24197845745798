import React, { Component } from "react"
import styled from "styled-components"

const Container = styled.div`
    display: ${props => (props.visible ? "flex" : "none")};
    align-items: center !important;
    align-content: center !important;
    justify-content: center !important;
    border-radius: 50%;
    margin: 0.25rem;
    padding: ${props => props.padding}rem;
    width: ${props => props.width}rem !important;
    height: ${props => props.height}rem !important;
    min-width: ${props => props.width}rem !important;
    min-height: ${props => props.height}rem !important;
    max-width: ${props => props.height}rem !important;
    max-height: ${props => props.height}rem !important;
    background-color: ${props => props.backgroundColor};
    border: 2px solid ${props => props.borderColor};

    &:hover {
        background-color: ${props => props.backgroundColorOnHover};
        border: 2px solid ${props => props.borderColorOnHover};
        cursor: pointer;
    }
    path {
        fill: ${props => props.iconColor};
        -webkit-transition: background-color 300ms linear;
        -ms-transition: background-color 300ms linear;
        transition: background-color 300ms linear;
    }
    &:hover path {
        fill: ${props => props.iconColorOnHover};
    }
`

export default class SVGIcon extends Component {
    render() {
        const { ...props } = this.props
        const Padding = 0.75
        const Width = 3
        const Height = 3
        const Visible = false === this.props.visible ? false : true

        const IconColor = this.props.iconColor
            ? this.props.iconColor
            : "tranparent"
        const IconColorOnHover = this.props.iconColorOnHover
            ? this.props.iconColorOnHover
            : "tranparent"
        const BackgroundColor = this.props.backgroundColor
            ? this.props.backgroundColor
            : "tranparent"
        const BackgroundColorOnHover = this.props.backgroundColorOnHover
            ? this.props.backgroundColorOnHover
            : "tranparent"
        const BorderColor = this.props.borderColor
            ? this.props.borderColor
            : "tranparent"
        const BorderColorOnHover = this.props.borderColorOnHover
            ? this.props.borderColorOnHover
            : "tranparent"

        return (
            <Container
                onClick={this.props.onClick ? this.props.onClick : undefined}
                width={Width}
                height={Height}
                padding={Padding}
                visible={Visible}
                iconColor={IconColor}
                iconColorOnHover={IconColorOnHover}
                backgroundColor={BackgroundColor}
                backgroundColorOnHover={BackgroundColorOnHover}
                borderColor={BorderColor}
                borderColorOnHover={BorderColorOnHover}
                {...props}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    x="0px"
                    y="0px"
                    width="100%"
                    height="100%"
                    viewBox={this.props.viewbox}
                >
                    <path d={this.props.path} />
                </svg>
            </Container>
        )
    }
}
