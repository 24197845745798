import {Colors, Settings} from "./../../Theme"
import ActivityList from "./ActivityList"
import ProgressBar from "./../timeline/Node/ProgressBar"
import React, { Component } from "react"
import styled from "styled-components"
import SameDate from "../_helpers/Date/SameDate";
import SwitchIcon from "../layout/Icons/Switch";
import hideNode from "../pit/hideNode";


const Container = styled.div`
    display: ${props => (props.visible ? "flex" : "none")};
    flex-direction: row;
    width: 100%;   
`;

const NodeContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    width: 100%;
    background-color: ${Colors.white};
    border-top: 0.5rem solid ${Colors.background};
    border-bottom: 0.5rem solid ${Colors.background};
    box-shadow: ${Settings.box_shadow};
    margin-bottom: 1rem;
    
    > * {
        padding: 1rem 1rem 0 1rem;
        &:last-child {
            padding: 1rem;
        }
    }

    &:hover {
        cursor: pointer;
    }
`;

const Content = styled.div`
    flex-grow: 2;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    font-size: 1rem;
    color: ${Colors.text};
`;

const DetailBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const Title = styled.div`
    display: flex;
    line-height: 1rem;
`;

const Time = styled.div`
    align-items: flex-end;
    color: ${Colors.text_light};
    display: flex;
    font-size: 0.8rem;
    line-height: 1.2rem;
`;

export default class NodeInList extends Component {
    onHideClick = event =>
    {
        const node = this.props.node;
        node.isHidden = !node.isHidden;

        hideNode(node).then(res => {
            this.props.updateNode(node);
        });
    };

    render() {
        const Node = this.props.node;

        var timeString = "";
        if (this.props.node.start) {
            if (this.props.node.start === this.props.node.due) {
                timeString = this.props.node.start.substr(
                    this.props.node.start.length - 5
                );
            } else {
                timeString =
                    this.props.node.start.substr(
                        this.props.node.start.length - 5
                    ) +
                    " - " +
                    this.props.node.due.substr(this.props.node.due.length - 5);
            }
        }

        var now = new Date();

        const momentStart = new Date(Node.start);
        const momentEnd = new Date(Node.due);

        var progressPercentage = 0;
        if (momentEnd < now) {
            progressPercentage = 100;
        } else if (momentStart > now) {
            progressPercentage = 0;
        } else if (SameDate(momentStart, now)) {
            if (momentStart.getTime() < now.getTime() < momentEnd.getTime()) {
                const PeriodDuration = momentEnd.getTime() - momentStart.getTime();
                const CurrentTimestampWithinPeriod =
                    now.getTime() - momentStart.getTime();
                progressPercentage = Math.round(
                    (100 / PeriodDuration) * CurrentTimestampWithinPeriod
                );
            }
        }

        return (
            <Container visible={this.props.visible}>
                <ProgressBar
                    percentage={progressPercentage}
                    first={this.props.node.number === 1}
                    last={this.props.node.number === this.props.totalAmountOfNodes}
                />
                <NodeContent>
                    <DetailBar>
                        <Content>
                            <Title>{this.props.node.title}</Title>
                        </Content>
                        <Time>{timeString}</Time>
                        {Node.isHideable && !Node.isHidden && <SwitchIcon
                            iconColor={Colors.green}
                            iconColorOnHover={Colors.green_dark}
                            onClick={(e) => this.onHideClick(e)}
                        />}

                        {Node.isHideable && Node.isHidden && <SwitchIcon
                          iconColor={Colors.red}
                          iconColorOnHover={Colors.red_dark}
                          onClick={(e) => this.onHideClick(e)}
                        />}
                    </DetailBar>
                    {!Node.isHidden && <ActivityList
                        activities={this.props.node.activities}
                        updateNodes={this.props.updateNodes}
                        selectActivity={this.props.selectActivity}
                    />}
                </NodeContent>
            </Container>
        )
    }
}
