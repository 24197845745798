import { Colors } from "../../Theme";
import React, { Component } from "react";
import styled from "styled-components";

const Container = styled.div`
    color: ${Colors.text};
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
`;

const Outer = styled.div`
    background-color: ${Colors.gray_lightest};
    overflow: hidden;
    display: flex;
    width: 100%;
    height: 1.5rem;
    border-radius: 1rem;
    margin: 0;
    padding: 0;
`;

const Inner = styled.div`
    color: ${props => (props.textColor ? props.textColor : Colors.white)};
    display: flex;
    font-size: 0.7rem;
    font-family: "Open Sans Bold";
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-right: ${props =>
        props.score && props.score > 0 ? "0.5rem" : "0"};
    background-color: ${props =>
        props.barColor ? props.barColor : Colors.gray_lightest};
    height: 100%;
    border-radius: 100px;
    width: ${props => (props.score ? `${props.score}%` : "0%")};
    -webkit-transition: width 500ms, height 500ms;
    transition: width 500ms, height 500ms;
`;

const ScoreContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-family: "Open Sans Bold";
    font-size: 1.3rem;
    padding-bottom: 0.5rem;
`;

export default class Scorebar extends Component {
    render() {
        var Score = this.props.score

        if (Score > 100) {
            Score = 100
        }

        var BarColor = Colors.gray_lightest
        var TextColor = Colors.gray
        if (Score > 0 && Score < 50) {
            BarColor = Colors.red
            TextColor = Colors.white
        } else if (Score < 79) {
            BarColor = Colors.orange
            TextColor = Colors.orange_dark
        } else if (Score < 94) {
            BarColor = Colors.green
            TextColor = Colors.green_dark
        } else if (Score >= 95) {
            BarColor = Colors.green
            TextColor = Colors.green_dark
        }

        return (
            <Container>
                <ScoreContainer>
                    {Score}%
                </ScoreContainer>
                <Outer>
                    <Inner
                        score={Score}
                        barColor={BarColor}
                        textColor={TextColor}
                    />
                </Outer>
            </Container>
        )
    }
}
