/**
 * @param Date date
 * @returns {string}
 * @constructor
 */
export default function FormatDate(date) {
	let
		month = '' + (date.getMonth() + 1),
		day = '' + date.getDate(),
		year = date.getFullYear();

	if (month.length < 2)
		month = '0' + month;
	if (day.length < 2)
		day = '0' + day;

	return [year, month, day].join('');
}

/**
 * @param Date date
 * @returns {string}
 * @constructor
 */
export function FormatHumanDate(date) {
	let
		month = '' + (date.getMonth() + 1),
		day = '' + date.getDate(),
		year = date.getFullYear();

	if (month.length < 2)
		month = '0' + month;
	if (day.length < 2)
		day = '0' + day;

	return [day, month, year].join('-');
}