import { Colors } from "../../Theme"
import Auth from "../authentication/Auth"
import PitLogo from "./../pit/Logo"
import React, { Component } from "react"
import styled from "styled-components"
import MenuIcon from "./../layout/Icons/Menu"

const Container = styled.div`
    width: 100%;
    margin: 0;
    background: ${Colors.primary};
    color: ${Colors.white};
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    height: 65px;
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    
    > div {
        flex: 1;
    }
    
    > div:nth-of-type(1) {
        text-align: left;
        &:last-child {
            min-width: 3rem;
            min-height: 3rem;
            margin: 0.25rem;
        }
    }
    
    > div:nth-of-type(2) {
      text-align: center;
    }
    
    > div:nth-of-type(3) {
      text-align: right;
    }
    
`

const Name = styled.div`
    padding-right: 1rem;
    font-style: italic;
    
`

export default class Navigation extends Component {
    render() {
        if (Auth.isAuthenticated()) {
            return (
                <Container>
                    <MenuIcon onClick={this.props.toggleMenu} />
                    <PitLogo
                        size={2}
                        padding={0}
                        background="transparent"
                        color={Colors.white}
                        onClick={this.props.refresh}
                    />
                    <Name onClick={this.props.toggleTimelinePicker}>{this.props.selectedTimelineName}</Name>
                </Container>
            )
        }
    }
}
