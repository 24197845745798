import React, { Component } from "react"
import { Colors } from "../../../Theme"
import SVGIcon from "../SVGIcon"

export default class CheckboxChecked extends Component {
    render() {
        const { ...props } = this.props

        return (
            <SVGIcon
                path="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24zm-5.541 8.409l-1.422-1.409-7.021 7.183-3.08-2.937-1.395 1.435 4.5 4.319 8.418-8.591z"
                viewbox="0 0 24 24"
                iconColor={Colors.green}
                {...props}
            />
        )
    }
}
