import {ENDPOINTS} from "./Endpoints"
import {POST} from "./Requests"

export default async function GetUserData() {
    try {
        let url = ENDPOINTS.getUserData

        return POST(url)
    } catch (error) {
        console.error(error)
    }
}
