import React, { Component } from "react"
import { Colors } from "../../Theme"
import ListItem from "./../layout/ListItem"
import styled from "styled-components"

const Container = styled.div`
    display: flex;
    padding-top: 1rem;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
`

const Message = styled.div`
    padding: 1rem;
    color: ${Colors.gray};
    width: 100%;
`

export default class TipActivities extends Component {
    render() {
        if (this.props.tips.length > 0) {
            return (
                <Container>
                    {this.props.tips.map(tip => (
                        <ListItem key={tip.id} content={tip.title} />
                    ))}
                </Container>
            )
        } else {
            return (
                <Container>
                    <Message>No TIPs for this day</Message>
                </Container>
            )
        }
    }
}
