import { Colors } from "./../../../Theme"
import React, { Component } from "react"
import Button from "./../Button"

export default class Confirm extends Component {
    render() {
        const { ...props } = this.props
        return (
            <Button
                textColor={Colors.white}
                borderColor={Colors.green}
                backgroundColor={Colors.green}
                textColorOnHover={Colors.white}
                borderColorOnHover={Colors.green_dark}
                backgroundColorOnHover={Colors.green_dark}
                {...props}
            />
        )
    }
}
