import { ENDPOINTS } from "./Endpoints"
import { NotifyError } from "../layout/Notification"
import { POST } from "./Requests"

function checkValues(activityValues) {
    var Activity = {
        id: activityValues.id,
        completed: activityValues.completed,
        note: activityValues.note,
        escalated: activityValues.escalated,
        escalationReason: activityValues.escalationReason,
        notification: activityValues.notification,
        photo: false,
        checks: activityValues.checks
    }

    if (typeof activityValues.photo === "number") {
        Activity.photo = activityValues.photo
    }

    if (activityValues.checks.length > 0) {
        let ChecklistItemAmountFilled = 0;
        console.log(activityValues.checks);
        activityValues.checks.forEach((ChecklistItem, key) => {
            if (ChecklistItem.value === "") {
                // Convert empty string to null (the original value used by backend).
                ChecklistItem.value = null
                activityValues.checks[key].value = null
            }

            if (ChecklistItem.value !== null) {
                ChecklistItemAmountFilled++
            }
        });

        if (activityValues.checks.length === ChecklistItemAmountFilled) {
            Activity.completed = true
        } else {
            NotifyError("Nog niet alle waarden van de checklist zijn ingevuld.")
            Activity.completed = false
        }
    }

    if (activityValues.completionType === "photo") {
        if (Activity.completed === true && Activity.photo === false) {
            NotifyError("Bij deze taak is een foto verplicht")
            Activity.completed = false
        } else if (Activity.completed === true && Activity.photo === "") {
            // Set Activity as incomplete if photo is deleted (backend returns empty string in that case)
            Activity.completed = false
        } else {
            Activity.completed = true
        }
    }

    return Activity
}

export default function saveActivity(ActivityValues) {
    const Activity = checkValues(ActivityValues)
    return POST(ENDPOINTS.updateActivity, { activity: Activity })
}
