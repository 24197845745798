import { Colors } from "./../../Theme"
import Datepicker from "./../navigation/Datepicker"
import React, { Component } from "react"
import styled from "styled-components"

const Container = styled.div`
    height: calc(100vh - 65px);
`

const Section = styled.div`
    margin-top: 1.5rem;
    &:first-child {
        margin-top: 0;
    }
`

const VersionSection = styled.div`
    margin-top: 0.5rem;
    &:first-child {
        margin-top: 0;
    }
    font-weight: regular;
    font-size: 0.6rem;
`

const SectionName = styled.div`
    padding: 0.5rem 1em 0.5rem 1rem;
    font-size: 1rem;
    color: ${Colors.text_light};
    font-weight: bold;
    border-bottom: 0.1rem solid #e8e8e8;
    text-transform: uppercase;
`

const MenuItemContainer = styled.div``

const MenuItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 1.5em 0.5rem 1rem;
    font-weight: bold;
    &:hover {
        background: ${props => props.hover ? Colors.hover : ""};
        cursor: ${props => props.hover ? "pointer" : ""};
    }
`

const SubMenuItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.2rem 1.5em 0.2rem 1.5rem;
    font-style: italic;
    font-size: 1rem;
   
    &:hover {
        background: ${Colors.hover};
        cursor: pointer;
    }
`

export default class Menu extends Component {
    render() {
        const {
            currentView,
            groups,
            timeLines,
            selectedTimelineId,
            changeTimeline,
            changeViewFunction,
            logoutFunction
        } = this.props

        let MenuSections = []

        MenuSections.push({
            name: "View",
            showSectionName: true,
            items: [
                {
                    id: 1,
                    label: "Timeline",
                    active: currentView === "timeline",
                    onClick: () => changeViewFunction("timeline"),
                    subitems: [],
                    hover: true
                },
                {
                    id: 2,
                    label: "TIPs",
                    active: currentView === "tips",
                    onClick: () => changeViewFunction("tips"),
                    subitems: [],
                    hover: true
                },
                {
                    id: 3,
                    label: "MEP",
                    active: currentView === "mep",
                    onClick: () => changeViewFunction("mep"),
                    subitems: [],
                    hover: true
                },
                {
                    id: 3,
                    label: "FAQ",
                    active: currentView === "faq",
                    onClick: () => changeViewFunction("faq"),
                    subitems: [],
                    hover: true
                }
            ]
        })

        if (typeof timeLines === "object" && timeLines.length > 0) {
            let MenuSectionGroups = {
                name: "Timelines",
                showSectionName: true,
                items: []
            }

            var filteredTimeLinesByGroup = []
            for (const group of groups) {
                const groupTimelines = timeLines.filter(timeline => timeline.groupId === group.id);
                filteredTimeLinesByGroup.push({ ...group, timelines: groupTimelines });
            }


            filteredTimeLinesByGroup.forEach(Group => {
                var timelines = []

                if (
                    typeof Group.timelines === "object" &&
                    Group.timelines.length > 0
                ) {
                    MenuSectionGroups.items.push({
                        label: Group.name,
                        subitems: timelines
                    })
                    Group.timelines.forEach(Timeline => {
                        timelines.push({
                            label: Timeline.location,
                            hover: false,
                            active: selectedTimelineId === Timeline.id,
                            onClick: () => changeTimeline(Timeline.id, Timeline.location, Group.name, Timeline.dayStartTime)
                        })
                    })
                }

            })
            MenuSections.push(MenuSectionGroups)
        }

        MenuSections.push({
            name: "Uitloggen",
            showSectionName: false,
            items: [
                {
                    id: 3,
                    label: "Uitloggen",
                    subitems: [],
                    onClick: logoutFunction,
                    hover: true
                }
            ]
        })

        return (
            <Container>
                <Section>
                    <Datepicker
                        date={this.props.date}
                        goToDate={this.goToDate}
                        selectedDate={this.props.selectedDate}
                        showDatepicker={this.props.showDatepicker}
                        hideDatepicker={this.props.hideDatepicker}
                        datepickerVisible={this.props.datepickerVisible}
                    />
                </Section>
                {MenuSections.map((section, key) => (
                    <Section key={key}>
                        {section.showSectionName ? (
                            <SectionName>{section.name}</SectionName>
                        ) : null}
                        <div>
                            {section.items.map((item, key) => (
                                <MenuItemContainer
                                    key={key}
                                    onClick={item.onClick ? item.onClick : null}
                                >
                                    <MenuItem
                                        hover={item.hover ? true : false}
                                    >
                                        <div>{item.label}</div>
                                        {item.active ? <div>&bull;</div> : null}
                                    </MenuItem>
                                    {item.subitems.map((Subitem, key) => (
                                        <SubMenuItem
                                            onClick={
                                                Subitem.onClick
                                                    ? Subitem.onClick
                                                    : null
                                            }
                                            key={key}
                                        >
                                            <div>{Subitem.label}</div>
                                            {Subitem.active ? (
                                                <div>&bull;</div>
                                            ) : null}
                                        </SubMenuItem>
                                    ))}
                                </MenuItemContainer>
                            ))}
                        </div>
                    </Section>
                ))}
                <VersionSection>
                    <div>Version: {this.props.version}</div>
                </VersionSection>
            </Container>
        )
    }
}
