import { Colors } from "../../Theme"
import React, { Component } from "react"
import styled from "styled-components"

const Container = styled.div`
    width: ${props => props.width}rem !important;
    min-width: ${props => props.width}rem !important;
    max-width: ${props => props.width}rem !important;
    height: ${props => props.height}rem !important;
    min-height: ${props => props.height}rem !important;
    max-height: ${props => props.height}rem !important;
    padding: ${props => props.padding}rem;
    background-color: ${props => props.background};
    display: flex;
    align-items: center !important;
    align-content: center !important;
    justify-content: center !important;
    border-radius: 50%;

    border: ${props =>
        props.border === true && props.borderColor
            ? `1px solid ${props.borderColor}`
            : "none"};

    svg {
        path {
            fill: ${props => props.color};
        }
    }

    &:hover {
        cursor: pointer;
        border: ${props =>
            props.border === true && props.borderColor
                ? `1px solid ${Colors.primary}`
                : "none"};
        background-color: ${props => props.backgroundOnHover};

        svg {
            path {
                fill: ${props => props.colorOnHover};
            }
        }
    }
`

export default class PitLogo extends Component {
    render() {
        const Padding = this.props.padding ? this.props.padding : 1
        const Size = this.props.size ? this.props.size : 1
        const Width = Size + Padding + Padding
        const Height = Size + Padding + Padding
        const Border = this.props.borderColor ? true : false
        const BorderColor = this.props.borderColor
            ? this.props.borderColor
            : "transparent"

        const Background = this.props.background
            ? this.props.background
            : "transparent"

        return (
            <Container
                onClick={this.props.onClick ? this.props.onClick : null}
                width={Width}
                height={Height}
                padding={Padding}
                color={this.props.color}
                background={Background}
                border={Border}
                borderColor={BorderColor}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    x="0px"
                    y="0px"
                    width="100%"
                    height="100%"
                    viewBox="0 0 116.71 51.06"
                >
                    <path
                        d="M564.55,167.7v14.59H550V131.23h29.18q18.23,0,18.23,18.24T579.14,167.7Zm0-21.88v7.29h14.59q3.65,0,3.64-3.64c0-2.44-1.21-3.65-3.64-3.65Z"
                        transform="translate(-549.96 -131.23)"
                    />
                    <path
                        d="M615.61,131.23v51.06H601V131.23Z"
                        transform="translate(-549.96 -131.23)"
                    />
                    <path
                        d="M650.11,145.82v36.47H635.52V145.82H619.25V131.23h47.42l-7.3,14.59Z"
                        transform="translate(-549.96 -131.23)"
                    />
                </svg>
            </Container>
        )
    }
}
