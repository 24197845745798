import { Colors } from "./../../../Theme"
import React, { Component } from "react"
import styled from "styled-components"
import TextInputField from "./../../layout/TextInputField"

const Container = styled.div`
    /* background: red; */
    margin-bottom: 2rem;
`

const Label = styled.div`
    color: ${props => props.textColor};
    padding: 0.25rem 0 1rem 0;
    font-size: 0.8rem;
`
const List = styled.div`
    padding: 1rem;
    border: 1px solid ${Colors.white_dark};
`

export default class Checklist extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checklistItems: this.props.items
        }
        this.checklistItemUpdated = this.checklistItemUpdated.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.items !== this.props.items) {
            this.setState({
                checklistItems: this.props.items
            })
        }
    }

    checklistItemUpdated(id, value) {
        const ChecklistItems = this.state.checklistItems

        ChecklistItems.map(ChecklistItem => {
            if (ChecklistItem.id === id) {
                ChecklistItem.value = value
            }
            return ChecklistItem
        })
        this.props.updateChecklist(this.state.checklistItems)
    }

    render() {
        const ChecklistItems = this.state.checklistItems

        return (
            <Container>
                <Label textColor={this.props.error ? Colors.red : Colors.gray}>
                    Checklist
                </Label>
                <List>
                    {ChecklistItems.map((item, key) => (
                        <div key={key}>
                            <TextInputField
                                type={item.typeName}
                                name={item.id}
                                value={item.value}
                                valueOfTextfieldUpdated={(value) => this.checklistItemUpdated(item.id, value)}
                                label={item.title}
                            />
                        </div>
                    ))}
                </List>
            </Container>
        )
    }
}
