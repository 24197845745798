import { ENDPOINTS } from "./Endpoints"
import { UPLOAD } from "./Requests"

export default async function UploadFile(File) {
    try {
        const body = new FormData()
        body.append("file", File)
        return UPLOAD(ENDPOINTS.uploadFile, body)
    } catch (error) {
        console.error(error)
    }
}
