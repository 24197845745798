let PIT_BACKEND_URL = false
if (
    process.env.REACT_APP_PIT_DOMAIN !== undefined ||
    process.env.REACT_APP_PIT_DOMAIN !== ""
) {
    PIT_BACKEND_URL = "https://" + process.env.REACT_APP_PIT_DOMAIN
}

export default {
    url: PIT_BACKEND_URL
}
