import Clock from "react-live-clock";
import React, { Component } from "react";
import SameDate from "../_helpers/Date/SameDate";

export default class TickingClock extends Component {
    state = {
        today: false
    };

    componentDidMount() {
        const today = new Date();
        const selectedDate = this.props.selectedDate;
        this.setState({ today: SameDate(selectedDate, today) })
    }

    render() {
        if (this.state.today === true) {
            return (
                <Clock
                    format={"H:mm"}
                    ticking={true}
                    timezone={"Europe/Amsterdam"}
                />
            )
        } else {
            return ""
        }
    }
}
