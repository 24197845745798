import { Colors, Settings } from "../../Theme"
import React, { Component } from "react"
import styled from "styled-components"

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
`

const ButtonComponent = styled.div`
    padding: ${props => props.padding};
    margin: ${props => props.margin};
    text-align: center;
    outline: none;
    -webkit-appearance: none;
    font-size: 1rem;
    font-family: "Open Sans Bold";
    border-radius: ${Settings.border_radius};
    
    color: ${props => props.textColor};
    background-color: ${props => props.backgroundColor};
    border: 1px solid ${props => props.borderColor};

    &:hover {
        color: ${props => props.textColorOnHover};
        background-color: ${props => props.backgroundColorOnHover};
        border-color: ${props => props.borderColorOnHover};
        cursor: pointer;
    }
    &:focus {
        border: 1px solid ${Colors.button};
    }
    width: ${props => props.width};
`

export default class Button extends Component {
    render() {
        const { ...props } = this.props

        const TextColor = this.props.textColor
            ? this.props.textColor
            : Colors.text
        const TextColorOnHover = this.props.textColorOnHover
            ? this.props.textColorOnHover
            : Colors.text
        const BackgroundColor = this.props.backgroundColor
            ? this.props.backgroundColor
            : "tranparent"
        const BackgroundColorOnHover = this.props.backgroundColorOnHover
            ? this.props.backgroundColorOnHover
            : "tranparent"
        const BorderColor = this.props.borderColor
            ? this.props.borderColor
            : "tranparent"
        const BorderColorOnHover = this.props.borderColorOnHover
            ? this.props.borderColorOnHover
            : "tranparent"
        const Padding = this.props.padding
            ? this.props.padding
            : "1rem"
        const Margin = this.props.margin
            ? this.props.margin
            : "0"


        return (
            <Container>
                <ButtonComponent
                    textColor={TextColor}
                    textColorOnHover={TextColorOnHover}
                    backgroundColor={BackgroundColor}
                    backgroundColorOnHover={BackgroundColorOnHover}
                    borderColor={BorderColor}
                    borderColorOnHover={BorderColorOnHover}
                    padding={Padding}
                    margin={Margin}
                    {...props}
                >
                    {this.props.label}
                </ButtonComponent>
            </Container>
        )
    }
}
