import {Colors} from "../../../Theme"
import CameraIcon from "./../../layout/Icons/Camera"
import ConfirmButton from "./../../layout/Buttons/Confirm"
import DeleteButton from "./../../layout/Buttons/Delete"
import React, {useCallback, useEffect, useState} from "react"
import styled from "styled-components"
import UploadFile from "./../../pit/UploadFile"
import Popup from 'reactjs-popup';
import GetFileFromPIT from "../../pit/GetFile";

const Wrapper = styled.div`
    input {
        display: none;
    }
`;

const ImageWrapper = styled.div`
    width: 100%;
    height: 65vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  
    img {
        max-width:100%;
        max-height:100%;
    }
`;

const Modal = styled.div`
    margin: auto;
    font-size: 12px;
    border-radius: 5px;
    padding: .5rem;
    -webkit-animation: anvil .3s cubic-bezier(.38,.1,.36,.9) forwards;
    background-color: ${Colors.background};
    color: #000;
    border: 1px solid #464545;
    
    .header {
        width: 100%;
        border-bottom: 1px solid gray;
        font-size: 18px;
        text-align: center;
        padding: 5px;
    }
    
    .content {
        width: 100%;
        padding: 10px 5px;
    }
    
    .actions {
        width: 100%;
        padding: 10px 5px;
        margin: auto;
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .close {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: -10px;
        top: -10px;
        font-size: 24px;
        background: #ffffff;
        border-radius: 18px;
        border: 1px solid #cfcece;
    }
`;

const PhotoUpload = (props) => {

	const [{alt, src, file}, setImg] = useState({
		src: '',
		alt: '',
		file: null
	});

  const [photo, setPhoto] = useState(props.photo);
  const [popup, setPopup] = useState(false);
	const inputRef = React.createRef();

	const retrievePhotoByPhotoId = useCallback((photoId) => {
		GetFileFromPIT(photoId).then(res => {
			setImg({
				src: res,
				alt: photo.name,
				file: null
			});
		})
	}, [photo]);

	useEffect(()=>{
		if(photo)
			retrievePhotoByPhotoId(photo.id)
	}, [photo, retrievePhotoByPhotoId])

	const handleImg = (e) => {
		if (e.target.files[0]) {
			setImg({
				src: URL.createObjectURL(e.target.files[0]),
				alt: e.target.files[0].name,
				file: e.target.files[0]
			});
		}
		setPopup(true);
	}

	const cameraIconClicked = () => {
		if(photo)
			setPopup(true);
		else
			inputRef.current.click();
	};

	const cancelUpload = () => {
		setPopup(false);
		setImg({
			src: '',
			alt: '',
			file: null
		});
	}

	const resetInput = (e) => {
		e.target.value = null;
	}

	const confirmUpload = () => {
		setPopup(false);
		if (file) {
			UploadFile(file).then(res => {
				if (
					res.data !== undefined &&
					res.data.result !== undefined &&
					res.data.result.file !== undefined &&
					res.data.result.file.id !== undefined
				) {
					setImg({
						src: '',
						alt: '',
						file: null
					});

					setPhoto(res.data.result.file);
					props.afterUpload(res.data.result.file.id);
				}
			})
		}
	};

	const deletePhotoFromTask = () => {
		setPopup(false);
		setImg({
			src: '',
			alt: '',
			file: null
		});
		setPhoto(null);
		props.afterUpload(false)
	};

	return (
		<Wrapper id={props.id}>
			<input
				type="file"
				accept="image/png, image/jpeg"
				id="photo"
				onChange={handleImg}
				onClick={resetInput}
				ref={inputRef}
			/>

			<CameraIcon
				onClick={cameraIconClicked}
				active={photo !== null && photo.id !== null}
			/>

			<Popup closeOnDocumentClick
						 open={popup}
						 onClose={() => setPopup(false)}
						 position="right center"
						 modal
						 nested
			>
				<Modal>
					<button className="close" onClick={cancelUpload}>
						&times;
					</button>
					<div className="header"> Preview</div>
					<div className="content">
						{' '}
						<ImageWrapper>
							<img src={src} alt={alt} />
						</ImageWrapper>
					</div>
					<div className="actions">
						<DeleteButton
							key={3}
							label={ photo ? "Remove" : "Cancel" }
							onClick={ photo ? deletePhotoFromTask : cancelUpload}
						/>
						<ConfirmButton
							key={4}
							label={ photo ? "Ok" : "Upload" }
							onClick={ photo ? () => setPopup(false) : confirmUpload}
						/>
					</div>
				</Modal>

			</Popup>
		</Wrapper>
	);
}

export default PhotoUpload;
