import { Colors } from "../../../Theme"
import React, { Component } from "react"
import styled from "styled-components"

const ProgressBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    width: 3.5rem;
    max-width: 3.5rem;
`

const Progress = styled.div`
    display: flex;
    width: 0.25rem;
    max-width: 0.25rem;
    max-height: ${props => (props.maxHeight ? props.maxHeight : "none")};
    min-height: ${props => (props.minHeight ? props.minHeight : "none")};
    height: 100%;
    background: linear-gradient(
        to bottom,
        ${Colors.background},
        ${Colors.background} 50%,
        ${props => (props.hide ? Colors.background : Colors.primary)} 50%,
        ${props => (props.hide ? Colors.background : Colors.primary)}
    );
    background-size: 100% 0.5rem;
`

const Dot = styled.div`
    width: 1rem;
    height: 1rem;
    min-width: 1rem;
    min-height: 1rem;
    border-radius: 50%;
    background-color: ${props =>
        props.started ? Colors.primary : Colors.background};
    border: 2px solid ${Colors.primary};
`

const ProgressFilled = styled.div`
    width: 100%;
    height: ${props => (props.percentage ? `${props.percentage}%` : "0%")};
    background-color: ${props =>
        props.hide ? Colors.background : Colors.primary};
`

export default class ProgressBar extends Component {
    render() {
        const Percentage = this.props.percentage

        let MaxHeight = "1.5rem"
        let MinHeight = "1.5rem"
        if (this.props.last) {
            MaxHeight = "none"
            MinHeight = "98%"
        }

        return (
            <ProgressBarContainer>
                <Progress
                    hide={false}
                    maxHeight={MaxHeight}
                    minHeight={MinHeight}
                >
                    <ProgressFilled percentage={Percentage} hide={false} />
                </Progress>
                <Dot started={Percentage > 0} />
                <Progress hide={this.props.last}>
                    <ProgressFilled
                        percentage={Percentage}
                        hide={this.props.last}
                    />
                </Progress>
            </ProgressBarContainer>
        )
    }
}
