import { Colors } from "./../../Theme"
import React, { Component } from "react"
import styled from "styled-components"
import {FormatHumanDate} from "../_helpers/Date/FormatDate";

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: 1rem 0;
`

const RightContainer = styled.div`
    width: 100px;
    text-align:right;
    justify-content: center;
    font-family: "Open Sans";
    font-size: 0.8rem;
    padding-bottom: 0.5rem;
    float: right;
    padding-right:1rem;
`

const CenterContainer = styled.div`
    width: 100%;
    font-family: "Open Sans Bold";
    font-size: 1.3rem;
    padding-bottom: 0.5rem;
    text-align:center;
    margin-right: -100px;
`

const Button = styled.button`
    padding: 0.25rem 0.5rem;
    background-color: ${props =>
        props.active ? Colors.primary : "transparent"};
    border: 1px solid
        ${props => (props.active ? Colors.primary : Colors.gray_lightest)};
    color: ${props => (props.active ? Colors.white : Colors.text)};

    &:hover {
        cursor: pointer;
        background-color: ${props =>
            props.active ? Colors.primary : Colors.gray_lightest};
    }
    &:first-child {
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }
    &:last-child {
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }
`

export default class FilterButtons extends Component {
    render() {
        const Filters = this.props.activityFilters

        if (typeof Filters === "object") {
            return (
                <Container>
                    <CenterContainer>{Filters.map((Filter, index) => (
                        <Button
                            key={index}
                            onClick={Filter.action}
                            active={
                                Filter.name === this.props.appliedActivityFilter
                            }
                        >
                            {Filter.name}
                        </Button>
                    ))}
                    </CenterContainer>
                    <RightContainer>Taken van<br/>{this.props.displayDate ? FormatHumanDate(this.props.displayDate) : ""}</RightContainer>
                </Container>
            )
        } else {
            return null
        }
    }
}
