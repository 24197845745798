import { Colors } from "../../Theme"
import CalendarIcon from "./../layout/Icons/Calendar"
import moment from "moment"
import React, { Component } from "react"
import styled from "styled-components"
import TickingClock from "./../Score/TickingClock"

const Outter = styled.div`
    padding: 1rem;
`

const Container = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid ${Colors.gray_lightest};
    padding: 0.25rem;
    &:hover {
        background: ${Colors.hover};
        cursor: pointer;
    }
    div.react-datepicker__portal {
        align-content: center;
        align-items: flex-start;
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        padding-top: 1rem;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 3;
    }
`

const DateContainer = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    width: 100%;
    font-size: 0.8rem;
`

const Date = styled.div``
const TickingClockContainer = styled.div`
    padding-left: 0.25rem;
`

export default class Datepicker extends Component {
    state = {
        selectedDate: null
    }

    constructor(props) {
        super(props);

        this.setDate = this.setDate.bind(this)
    }

    componentDidMount() {
        if (this.props.selectedDate !== undefined) {
            this.setDate(this.props.selectedDate)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedDate !== prevProps.selectedDate) {
            this.setDate(this.props.selectedDate)
        }
    }

    setDate(date) {
        let SelectedDate = date;
        if (typeof SelectedDate === "string") {
            SelectedDate = moment(JSON.parse(SelectedDate))
        }

        this.setState({
            selectedDate: SelectedDate
        })
    }

    render() {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

        var DateAsText = "";
        if (this.state.selectedDate) {
            DateAsText =
                this.state.selectedDate.toLocaleDateString('nl-NL', options);
        }

        return (
            <Outter>
                <Container>
                    <DateContainer onClick={this.props.showDatepicker}>
                        <CalendarIcon />
                        <Date>{DateAsText}</Date>
                        <TickingClockContainer>
                            <TickingClock
                                selectedDate={this.props.selectedDate}
                            />
                        </TickingClockContainer>
                    </DateContainer>
                </Container>
            </Outter>
        )
    }
}
