import * as Yup from 'yup'

export const rules = Yup.object().shape({
    code:  Yup.string()
        .required('Verplicht'),
    password: Yup.string()
        .min(6, 'Wachtwoord dient uit minimaal 6 karakters te bestaan')
        .required('Verplicht'),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password')], 'Komt niet overeen')
        .required('Verplicht')
});