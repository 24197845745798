import React, { Component } from "react"
import { Colors } from "../../../Theme"
import SVGIcon from "../../layout/SVGIcon"

export default class CloseIcon extends Component {
    render() {
        const Background = Colors.gray_lightest
        const Color = Colors.text
        const Path =
            "M28.941,31.786L0.613,60.114c-0.787,0.787-0.787,2.062,0,2.849c0.393,0.394,0.909,0.59,1.424,0.59   c0.516,0,1.031-0.196,1.424-0.59l28.541-28.541l28.541,28.541c0.394,0.394,0.909,0.59,1.424,0.59c0.515,0,1.031-0.196,1.424-0.59   c0.787-0.787,0.787-2.062,0-2.849L35.064,31.786L63.41,3.438c0.787-0.787,0.787-2.062,0-2.849c-0.787-0.786-2.062-0.786-2.848,0   L32.003,29.15L3.441,0.59c-0.787-0.786-2.061-0.786-2.848,0c-0.787,0.787-0.787,2.062,0,2.849L28.941,31.786z"
        const { ...props } = this.props

        return (
            <SVGIcon
                path={Path}
                viewbox="0 0 64 64"
                color={Color}
                background={Background}
                {...props}
            />
        )
    }
}
