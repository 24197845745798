import React, { Component } from "react"
import { Colors } from "../../../Theme"
import SVGIcon from "../../layout/SVGIcon"

export default class CheckboxOpen extends Component {
    render() {
        const { ...props } = this.props
        return (
            <SVGIcon
                path="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24zm-6 16.538l-4.592-4.548"
                viewbox="0 0 24 24"
                iconColor={Colors.gray_lighter}
                iconColorOnHover={Colors.green_light}
                {...props}
            />
        )
    }
}
