import React, { Component } from "react"
import { Colors } from "../../../Theme"
import SVGIcon from "../SVGIcon"

export default class CheckboxOverdue extends Component {
    render() {
        const { ...props } = this.props
        return (
            <SVGIcon
                path="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24zm-6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z"
                viewbox="0 0 24 24"
                iconColor={Colors.red}
                iconColorOnHover={Colors.gray_lighter}
                {...props}
            />
        )
    }
}
