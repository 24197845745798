import { Colors } from "./../../Theme"
import React, { Component } from "react"
import styled from "styled-components"
import TextInputField from "./../layout/TextInputField"
import UpdateMepInPit from "./../pit/saveMep"
import ConfirmButton from "../layout/Buttons/Confirm";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const Container = styled.div`
    /* background: red; */
    margin-bottom: 2rem;
`

const Title = styled.div`
    margin: 2rem;
    text-align:center;
    font-weight:bold;
    
`

const Message = styled.div`
    padding: 1rem;
    color: ${Colors.gray};
    width: 100%;
    
`

const List = styled.div`
    padding: 1rem;
    margin: 1rem;
    & + & {
        border-bottom: 1px solid ${Colors.gray_dark};
    }
`

export default class MepItems extends Component {
    constructor(props) {
        super(props)
        this.state = {
            items: this.props.items,
            timeline: this.props.timeline,
            date: this.props.date
        }
        this.itemUpdated = this.itemUpdated.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.items !== this.props.items) {
            this.setState({
                items: this.props.items
            })
        }
    }

    itemUpdated(id, value) {
        const items = this.state.items

        items.map(item => {
            if (item.id === id) {
                item.value = value
            }
            return item
        })

        this.setState({
            items: items
        })
    }


    saveMEP() {
        UpdateMepInPit(this.state.items, this.state.timeline, this.state.date).then(res => {
            toast.success('Mise en Place saved');
        })
    }

    render() {
        const items = this.state.items;

        if (items.length > 0) {
            return (
                <Container>
                    <Title>Mise en Place</Title>
                    <List>
                        {items.map((item, index) => (
                            <div key={index}>
                                <TextInputField
                                    type={item.type}
                                    name={item.id}
                                    value={item.value}
                                    valueOfTextfieldUpdated={(value) => this.itemUpdated(item.id, value)}
                                    label={item.title}
                                />
                            </div>
                            ))}
                    </List>
                    <ConfirmButton
                        label="Opslaan"
                        onClick={() => this.saveMEP()}
                    />
                    <ToastContainer />
                </Container>
            )
        } else {
            return (
                <Container>
                    <Message>No Mise en Place for this day</Message>
                </Container>
            )
        }
    }
}
