import { Colors, Settings } from "../../Theme"
import React, { Component } from "react"
import styled from "styled-components"

const Container = styled.div`
    width: 100%;
    margin-bottom: 1rem;
    max-width: ${Settings.max_width};
    display: ${props => (props.visible ? "flex" : "none")};
    
    align-content: center;
    align-items: center;
    justify-content: center;
`;

const InputField = styled.input`
    border: none;
    
    font-size: 1rem;
    line-height: 2rem;
    flex: 1;
    outline: none;
    border: none;
    color: ${props => props.textColor} !important;
    -webkit-text-fill-color: ${props => props.textColor} !important;
    
    -webkit-box-shadow: 0 0 0 100px ${props => props.backgroundColor} inset !important;
    border-bottom: 1px solid ${props => props.borderColor} !important;
    
    
    &:hover,
    &:focus,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        border-bottom: 1px solid ${Colors.blue};
        -webkit-text-fill-color: ${props => props.textColor};
    }
    
    &::placeholder {
        font-style: italic;
    }
`;

const Label = styled.div`
    color: ${props => props.textColor};
    padding: 0.25rem 0;
    font-size: 0.8rem;
    width: 20%;
`;

function LabelForInputField(props) {
    const Text = props.label;
    if (Text) {
        return <Label {...props}>{Text}</Label>
    }
    return "";
}

export default class TextInputField extends Component {

    render() {
        const BackgroundColor = this.props.backgroundColor
            ? this.props.backgroundColor
            : "transparent"

        if(this.props.visible) {
            return (
              <Container visible={true}>
                <LabelForInputField
                  label={this.props.label}
                  textColor={
                      this.props.error ? Colors.red : Colors.gray_darkest
                  }
                />

                <InputField
                  type={this.props.type}
                  placeholder={this.props.placeholder ? this.props.placeholder : this.props.label}
                  step={this.props.type === "number" ? "any" : ""}
                  name={this.props.name}
                  value={ this.props.value ? this.props.value : "" }
                  textColor={this.props.textColor}
                  borderColor={this.props.error ? Colors.red : Colors.gray_lighter}
                  backgroundColor={BackgroundColor}
                  onChange={(e) => this.props.valueOfTextfieldUpdated(e.target.value)}
                />
            </Container>);
        } else {
            return null;
        }
    }
}

TextInputField.defaultProps = {
    type: 'text',
    value: '',
    visible: true,
    textColor: Colors.text,
};
