import { get, set, clear } from "idb-keyval"
import PitLogin from "./../pit/Login"

const Auth = {
    timesCheckForAuthentications: 0,
    authenticated: false,
    async isAuthenticated() {
        await get("pit_token").then(val => {
            this.authenticated = typeof val !== "undefined"
        });

        this.timesCheckForAuthentications = this.timesCheckForAuthentications + 1;
        return this.authenticated;
    },
    login(username, password, callbackSuccess, callbackError) {
        PitLogin(username, password)
            .then(res => {
                if (401 === res.data.status) {
                    this.logout(callbackError)
                } else {
                    let Groups = false;
                    let TimeLines = false;
                    if (res.data.groups) {
                        Groups = JSON.stringify(res.data.groups)
                        set("pit_groups", Groups)
                    }
                    if (res.data.timeLines) {
                        TimeLines = JSON.stringify(res.data.timeLines)
                        set("pit_timelines", TimeLines)
                    }

                    set("pit_fullname", (res.data.fullname ? JSON.stringify(res.data.fullname) : ""));
                    set("pit_role", res.data.currentRole ? res.data.currentRole : "");
                    set("pit_timeline_id", res.data.currentTimeline ? (res.data.currentTimeline.id ? res.data.currentTimeline.id : -1) : -1);
                    set("pit_timeline_name", res.data.currentTimeline ? (res.data.currentTimeline.location ? res.data.currentTimeline.location : "") : "");
                    set("pit_group_name", (res.data.currentGroup.name ? res.data.currentGroup.name : ""));
                    set("pit_timeline_daystart", res.data.currentTimeline ? (res.data.currentTimeline.dayStartTime ? res.data.currentTimeline.dayStartTime : "00:00"): "00:00");


                    if (res.data.token) {
                        set("pit_token", res.data.token);
                        this.authenticated = true;
                        callbackSuccess(Groups)
                    } else {
                        this.logout(callbackError)
                    }
                }
            })
            .catch(res => {
                this.logout(callbackError)
            })
    },
    logout(callback) {
        clear()
        this.authenticated = false
        callback()
    }
}

export default Auth
