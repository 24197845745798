import { ENDPOINTS } from "./Endpoints"
import { POST } from "./Requests"

export default async function PitFAQ(timelineId) {
    try {
        let url =
            ENDPOINTS.getFAQ +
            "?timelineId=" +
            timelineId;

        return POST(url)
    } catch (error) {
        console.error(error)
    }
}
