import React, { Component } from "react"
import { Colors } from "../../../Theme"
import SVGIcon from "../../layout/SVGIcon"
import styled from "styled-components";

const Label = styled.div`
    color: ${props => props.textColor};
    font-size: 1rem;
    width: 100%;
    margin-left: 1rem;
    display: flex;
    align-items: center;
`

function LabelForInputField(props) {
    const Text = props.label
    if (Text) {
        return <Label {...props}>{Text}</Label>
    }
    return ""
}

const Container = styled.div`
    display: flex;
    border-radius: 0.5rem;
    margin: 1rem 0.25rem;
    width: ${props => props.height} !important;
    min-width: ${props => props.height}rem !important;
    max-width: ${props => props.height}rem !important;
    border: 1px solid ${props => props.borderColor};
    background-color: ${props => props.backgroundColor};
    
    
    &:hover {
        background-color: ${props => props.backgroundColorOnHover};
        border: 1px solid ${props => props.borderColorOnHover};
        cursor: pointer;
    }
    path {
        fill: ${props => props.iconColor};
        -webkit-transition: background-color 300ms linear;
        -ms-transition: background-color 300ms linear;
        transition: background-color 300ms linear;
    }
    &:hover path {
        fill: ${props => props.iconColorOnHover};
    }
`


export default class ToggleIcon extends Component {
    render() {
        const { ...props } = this.props

        const On = this.props.active
        const ColorName = this.props.colorName

        const Color = Colors[ColorName]
        const ColorLight = Colors[ColorName + "_light"]

        const IconColor = On ? Color : Colors.gray
        const IconColorOnHover = On ? ColorLight : Colors.red

        const BackgroundColor = this.props.background ? this.props.background : "transparent"
        const BackgroundColorOnHover = "transparent"

        const BorderColor = On ? Color : Colors.gray_lighter
        const BorderColorOnHover = On ? ColorLight : Colors.red

        const Visible = false === this.props.visible ? false : true

        return (
            <Container
                width={this.props.width}
                visible={Visible}
                borderColor={BorderColor}
                borderColorOnHover={BorderColorOnHover}
                backgroundColor={BackgroundColor}
                onClick={this.props.onClick ? this.props.onClick : undefined}
            >
            <SVGIcon
                iconColor={IconColor}
                iconColorOnHover={IconColorOnHover}
                backgroundColor={BackgroundColor}
                backgroundColorOnHover={BackgroundColorOnHover}
                {...props}
                onClick={undefined}
            />
                <LabelForInputField
                    label={this.props.label}
                    textColor={
                        this.props.error ? Colors.red : Colors.gray_darkest
                    }
                />
            </Container>
        )
    }
}
