import Swal from 'sweetalert2';

function ShowNotification(content, icon=null) {
    Swal.fire({
        text: content,
        position: "center",
        showConfirmButton: true,
	      timer: 3000,
	      timerProgressBar: true,
	      icon: icon
    })
}

export function NotifySuccess(content) {
    ShowNotification(content, "success");
}

export function NotifyWarning(content) {
    ShowNotification(content, "warning");
}

export function NotifyError(content) {
    ShowNotification(content, "error");
}
