import React, { Component } from "react";
import {Colors} from "../../../Theme";
import styled from "styled-components";

const Container = styled.div`
    padding: 0 0 0 1rem;
    border-radius: 5px;
    display: flex;
    align-self: center;
    align-items: center;
    min-width: ${props => props.height}rem !important;
    max-width: ${props => props.height}rem !important;
    border: ${props => props.border ? "2px solid " + props.borderColor : null};

    &:hover {
        background-color: ${props => props.hover ? props.backgroundColorOnHover : ""};
        border: ${props => props.hover ? "2px solid " + props.borderColorOnHover : ""};
        cursor: ${props => props.onClick ? "pointer" : null};
    }
    path {
        fill: ${props => props.iconColor};
        -webkit-transition: background-color 300ms linear;
        -ms-transition: background-color 300ms linear;
        transition: background-color 300ms linear;
    }
    &:hover path {
        fill: ${props => props.iconColorOnHover};
    }
`

export default class SwitchIcon extends Component {
    render() {
        return (
          <Container
            width={this.props.width}
            visible={this.props.visible}
            border={this.props.border}
            borderColor={this.props.borderColor}
            borderColorOnHover={this.props.borderColorOnHover}
            backgroundColorOnHover={this.props.backgroundColorOnHover}
            iconColor={this.props.mirror ? this.props.iconColorMirrored : this.props.iconColor}
            iconColorOnHover={this.props.mirror ? this.props.iconColorOnHoverMirrored : this.props.iconColorOnHover}
            onClick={this.props.onClick}
          >

            <svg width={this.props.width + "pt"} viewBox="0 0 512 270" xmlns="http://www.w3.org/2000/svg" transform={this.props.mirror ? "scale(-1, 1)" : ""}>
              <path fill="#048944" id="svg_1" d="m377.499,1.5l-241,0c-74.715,0 -135.499,60.786 -135.499,135.5s60.784,135.5 135.499,135.5l241,0c74.715,0 135.5,-60.785 135.5,-135.5s-60.785,-135.5 -135.5,-135.5zm-241,241.001c-58.173,0 -105.499,-47.328 -105.499,-105.501c0,-58.173 47.326,-105.5 105.499,-105.5c58.173,0 105.5,47.327 105.5,105.5c0,58.173 -47.327,105.501 -105.5,105.501z"/>
            </svg>
          </Container>
        )
    }
}

SwitchIcon.defaultProps = {
  width: 28,
  height: 28,
  visible: true,
  border: false,
  hover: false,
  iconColor: Colors.black,
  iconColorOnHover: Colors.black,
  borderColor: Colors.black,
  borderColorOnHover: Colors.black,
  backgroundColorOnHover: Colors.gray_lightest,
  onClick: undefined,
  mirror: false,
  iconColorMirrored: Colors.red,
  iconColorOnHoverMirrored: Colors.red
};

