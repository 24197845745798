import { Colors } from "../../Theme"
import Checkbox from "./Activity/Checkbox"
import equal from "fast-deep-equal"
import ListIcon from "./../layout/Icons/List"
import PhotoUpload from "./Activity/PhotoUpload.js"
import PropTypes from "prop-types"
import React, { Component } from "react"
import styled from "styled-components"
import UpdateActivityInPit from "./../pit/saveActivity"
import InfoIcon from "../layout/Icons/Info";

const Container = styled.div`
    display: ${props => (props.visible ? "flex" : "none")};
    flex-direction: row;
    align-items: center;
    align-content: flex-start;
    justify-content: space-between;
    width: 100%;
    background-color: ${props => props.backgroundColor};
    -webkit-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;

    &:hover {
        cursor: pointer;
    }
`;

const Content = styled.div`
    font-size: 1rem;
    flex-grow: 2;
    display: flex;
    line-height: 2.5rem;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    color: ${props =>
        props.completed ? Colors.background_darker : Colors.text};
`;

const ContentNew = styled.span`
    font-size: 0.7rem;
    font-weight: bold;
    line-height: 2.5rem;
    justify-content: space-between;
    margin-left: 0.5rem;
    vertical-align: super;
    color: ${Colors.red};
`;

export default class ActivityInList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activity: this.props.activity
        };
        this.clickCheckbox = this.clickCheckbox.bind(this)
        this.afterUpload = this.afterUpload.bind(this)
        this.updateDataOfActivity = this.updateDataOfActivity.bind(this)
        this.selectActivity = this.selectActivity.bind(this)
    }

    static propTypes = {
        activity: PropTypes.object.isRequired
    };

    static defaultProps = {
        activity: {}
    };

    updateDataOfActivity() {
        this.setState({ activity: this.props.activity })
    }

    componentDidUpdate(prevProps) {
        if (!equal(this.props.activity, prevProps.activity)) {
            this.updateDataOfActivity()
        }
    }

    clickCheckbox() {
        const Activity = this.state.activity
        const newValue = !this.state.activity.completed

        if (Activity.escalated !== true) {
            Activity["completed"] = newValue
            this.setState({ activity: Activity })
            UpdateActivityInPit(Activity).then(res => {
                this.props.updateNodes()
            })
        }
    }

    afterUpload(FileId) {
        const Activity = this.state.activity;
        Activity.photo = FileId !== false ? FileId : "";
        this.setState({ activity: Activity })

        UpdateActivityInPit(Activity).then(res => {
            this.props.updateNodes()
        })
    }

    selectActivity = () => {
        this.props.selectActivity(this.state.activity)
    };

    render() {
        let BackgroundColor = Colors.white
        if (this.state.activity.showAsNew) {
            BackgroundColor = Colors.green_littlelighter
        }
        return (
            <Container
                completed={this.state.activity.completed}
                backgroundColor={BackgroundColor}
                visible={this.props.activity.visible}
            >
                <Checkbox
                    type="checkbox"
                    status={this.state.activity.checkboxStatus}
                    onClick={this.clickCheckbox}
                />
                <Content
                    completed={this.state.activity.completed}
                    onClick={this.selectActivity}
                >
                    <div>
                        {this.state.activity.title}
                        {this.state.activity.showAsNew ? (
                            <ContentNew>nieuw!</ContentNew>
                        ) : null}
                    </div>
                </Content>
                {this.state.activity.description&&
                <InfoIcon
                  width={16}
                  height={16}
                  iconColor={Colors.blue}
                  iconColorOnHover={Colors.blue_darker}
                  onClick={this.selectActivity}
                />}

                {typeof this.state.activity.checks === "object" &&
                this.state.activity.checks.length > 0 ? (
                    <ListIcon onClick={this.selectActivity} />
                ) : null}

                {this.state.activity.completionType === "photo" ? (
                    <PhotoUpload
                        id={"photoUpload" + this.state.activity.id}
                        afterUpload={this.afterUpload.bind(this)}
                        photo={this.state.activity.photo}
                    />
                ) : null}
            </Container>
        )
    }
}
