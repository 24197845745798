import { Colors } from "../../Theme"
import NodeInList from "./NodeInList"
import React, { Component } from "react"
import styled from "styled-components"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 1rem 1rem 0;
`;

const Message = styled.div`
    padding: 1rem;
    color: ${Colors.gray};
    width: 100%;
`;

export default class Timeline extends Component {
    render() {
        const Nodes = this.props.nodes

        if (Nodes.length > 0) {
            return (
                <Container>
                    {Nodes.map(node => (
                        <NodeInList
                            visible={node.visible}
                            key={node.number}
                            node={node}
                            updateNodes={this.props.updateNodes}
                            totalAmountOfNodes={Nodes.length}
                            selectActivity={this.props.selectActivity}
                            updateNode={(n) => this.props.updateNode(n)}
                        />
                    ))}
                </Container>
            )
        } else {
            return (
                <Container>
                    <Message>Geen activiteiten gevonden op deze dag</Message>
                </Container>
            )
        }
    }
}
