import React, { Component } from "react"
import styled from "styled-components"
import CheckboxOpen from "./../../layout/Icons/CheckboxOpen"
import CheckboxChecked from "./../../layout/Icons/CheckboxChecked"
import CheckboxOverdue from "./../../layout/Icons/CheckboxOverdue"
import CheckboxEscalated from "./../../layout/Icons/CheckboxEscalated"

const Container = styled.div`
    display: flex;
    &:hover {
        cursor: pointer;
    }
`

const Checkbox = props => {
    switch (props.status) {
        case "checked":
            return <CheckboxChecked {...props} />
        case "overdue":
            return <CheckboxOverdue {...props} />
        case "escalated":
            return <CheckboxEscalated {...props} />
        default:
            return <CheckboxOpen {...props} />
    }
}

export default class ActivityCheckbox extends Component {
    render() {
        return (
            <Container>
                <Checkbox
                    status={this.props.status}
                    onClick={() => this.props.onClick("completed")}
                />
            </Container>
        )
    }
}
