import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { TextField } from 'formik-material-ui';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import Chip from '@material-ui/core/Chip';
import ErrorIcon from '@material-ui/icons/Error';
import PitLogo from "../pit/Logo";
import {Colors} from "../../Theme";
import * as validation from './login/validationRules';
import Auth from "./Auth";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
            height: "100vh"
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.common.black,
        width: 100,
        height: 100,
    },
    title: {
        margin: "1rem 0"
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function LoginForm(functions) {
    const classes = useStyles();
    const error = {status: false};

    return (
        <Container component="div" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar width={15} className={classes.avatar}>
                    <PitLogo color={Colors.white} size={4} />
                </Avatar>

                <Typography component="h1" variant="h5" className={classes.title}>
                    Login
                </Typography>

                <Formik
                    validationSchema={validation.rules}
                    validateOnChange={false}
                    validateOnBlur={true}
                    initialValues={{ email: '', password: '' }}
                    onSubmit={(values, { setSubmitting }) => {
                        Auth.login(
                            values.email,
                            values.password,
                            async Groups => {
                                error.status = false;
                                functions.loginSucces(Groups);
                            },
                            () => {
                                error.status = true;
                                error.message = "Deze gegevens zijn bij ons niet bekend."
                               setSubmitting(false);
                            }
                        )
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form className={classes.form}>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        variant="outlined"
                                        fullWidth
                                        label="Email Address"
                                        autoComplete="email"
                                        type="email"
                                        name="email"
                                    />

                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        variant="outlined"
                                        fullWidth
                                        id="password"
                                        label="Wachtwoord"
                                        name="password"
                                        type="password"
                                    />
                                </Grid>

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={isSubmitting}
                                >
                                    Login
                                </Button>
                                {error.status ?
                                    <Chip
                                        icon={<ErrorIcon />}
                                        label={error.message}
                                        className={classes.chip}
                                        color="secondary"
                                    /> : null }
                                <Grid container>
                                    <Grid item xs>
                                        <Link onClick={functions.forgotPassword} href="#" variant="body2">
                                            {"Wachtwoord vergeten?"}
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link onClick={functions.register} href="#" variant="body2">
                                            {"Registreer"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        </Container>
    );
}