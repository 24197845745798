/**
 * Checks if the dates of 2 date instances are the same
 * @param date
 * @param secondDate
 * @returns {boolean}
 * @constructor
 */
export default function SameDate(date, secondDate) {
	return date.getDate() === secondDate.getDate() &&
	date.getMonth() === secondDate.getMonth() &&
	date.getFullYear() === secondDate.getFullYear();
}