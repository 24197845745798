import { Colors } from "../../Theme"
import ActivityInList from "./ActivityInList"
import PropTypes from "prop-types"
import React, { Component } from "react"
import styled from "styled-components"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    background-color: ${Colors.white};
    &:hover {
        cursor: pointer;
    }
`
const ActivityContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: flex-start;
`

const Category = styled.div`
    width: 100%;
    padding: 0.5rem;
    font-size: 0.8rem;
    text-transform: uppercase;
    background: ${Colors.background};
    display: ${props => (props.visible ? "flex" : "none")};
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
`

export default class ActivityList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activities: this.props.activities
        }
    }

    static propTypes = {
        activities: PropTypes.array.isRequired
    }

    static defaultProps = {}

    render() {
        let prevCategory = ""
        let categoryVisible = true

        const Activities =
            this.props.activities !== undefined ? this.props.activities : []

        if (Activities.length === 0) {
            return ""
        } else {
            return (
                <Container>
                    {Activities.map((activity, i) => {
                        if (prevCategory === activity.category) {
                            categoryVisible = false
                        } else if (prevCategory !== activity.category) {
                            categoryVisible = true
                        }

                        prevCategory = activity.category

                        let HTML = (
                            <ActivityContainer key={i}>
                                <Category visible={categoryVisible}>
                                    {activity.category}
                                </Category>
                                <ActivityInList
                                    activity={activity}
                                    due={this.props.due}
                                    updateNodes={this.props.updateNodes}
                                    selectActivity={this.props.selectActivity}
                                />
                            </ActivityContainer>
                        )

                        categoryVisible = false

                        return HTML
                    })}
                </Container>
            )
        }
    }
}
