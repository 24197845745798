import { Colors } from "./../../../Theme"
import React, { Component } from "react"
import Button from "./../Button"

export default class Delete extends Component {
    render() {
        const { ...props } = this.props
        const Label = this.props.label ? this.props.label : "Verwijderen"

        return (
            <Button
                label={Label}
                textColor={Colors.red_lightest}
                textColorOnHover={Colors.white}
                backgroundColor={Colors.red}
                {...props}
            />
        )
    }
}
