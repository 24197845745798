import { ENDPOINTS } from "./Endpoints"
import { get } from "idb-keyval"
import { POST } from "./Requests"
import FormatDate from "../_helpers/Date/FormatDate";


export default async function PitNodes(date) {
    if (typeof date !== "string" && typeof date !== "object") {
        console.error("Given date not valid")
        return
    }

    try {
        let timelineId = await get("pit_timeline_id")

        if (typeof date === "string") {
            date = new Date(JSON.parse(date));
        }

        let url =
            ENDPOINTS.getNodes +
            "?timelineId=" +
            timelineId +
            "&date=" +
            FormatDate(date);

        return POST(url)
    } catch (error) {
        console.error(error)
    }
}
