import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import PasswordResetForm from "./PasswordResetForm";
import NewPasswordForm from "./NewPasswordForm";

export default function Login(props) {
    const [register, setRegister] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [setNewPassword, setSetNewPassword] = useState(false);

    const showRegister = (show) => {
        setRegister(show);
    };

    const showForgotPassword = (active) => {
        console.log('forgot password');
        setForgotPassword(active);
    }

    const showSetNewPassword = (active) => {
        console.log('get Code');
        setSetNewPassword(active);
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            {register ? <RegisterForm close={() => showRegister(false)} /> :
                null}
            {forgotPassword ? <PasswordResetForm close={() => showForgotPassword(false)} setNewPassword={() => {showSetNewPassword(true); showForgotPassword(false)}} /> :
                null}
            {setNewPassword ? <NewPasswordForm close={() => showSetNewPassword(false)} /> :
                null}

            {!forgotPassword && !register && !setNewPassword ? <LoginForm {...props} forgotPassword={() => showForgotPassword(true)} register={() => showRegister(true)} /> :
                null}

        </Container>
    );
}