import React, { Component } from "react"
import styled from "styled-components"
import { Colors, Settings } from "../../../Theme"

const Container = styled.div`
    width: 100%;
    max-width: 25%;
    margin-bottom: 2rem;
    max-width: ${Settings.max_width};
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
`

const ImageContainer = styled.div`
    padding: 5px;
    margin: 1rem;
    width: 300px;

    > img {
        border: 1px solid #ddd;
        border-radius: 4px;
        max-width: 100%;
        max-height: 100%;
    }

    > img:hover {
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    }
`

const Label = styled.div`
    color: ${Colors.text_lighter};
    padding: 0.5rem 0;
    font-size: 0.8rem;
`

function LabelForInputField(props) {
    const Text = props.label
    if (Text) {
        return <Label>{Text}</Label>
    }
    return ""
}

export default class Pictures extends Component {
    render() {
        const PictureItems =
            this.props.items === undefined ? [] : this.props.items

        if (PictureItems.length === 0) return ""

        return (
            <Container>
                <LabelForInputField label="Afbeeldingen" />
                {PictureItems.map(image => (
                    <ImageContainer>
                        <img src={image.url} alt="Uploaded by user" />
                    </ImageContainer>
                ))}
            </Container>
        )
    }
}
