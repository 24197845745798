import PitLogo from "./img/pit.svg";
import { lighten, darken } from "polished";
import _ from "lodash";

export const Settings = {
    max_width: "100%",
    max_width_content: "640px",
    border_radius: "20px",
    date_format: "YYYY-MM-DD HH:mm",
    allowed_time_to_take_photo: 15,
    box_shadow: "0px 5px 20px 0px rgba(0,0,0,0.2)",
    colorChangeSM: 0.1,
    colorChangeSMD: 0.25,
    colorChangeMD: 0.4,
    colorChangeLG: 0.5
};

export const Colors = {
    background: "#ffffff",
    primary: "#2a2d44",
    blue: "#2c94ff",
    text: "#21222b",
    white: "#ffffff",
    black: "#000000",
    gray: "#51525d",
    green: "#16A085",
    orange: "#ffbc34",
    red: "#ff3434",
    hover: "#adccff",
    highlight: "#e2e2e2"
};

_.map(Colors, (color, name) => {
    Colors[name + "_light"] = lighten(Settings.colorChangeSM, color)
    Colors[name + "_littlelighter"] = lighten(Settings.colorChangeSMD, color)
    Colors[name + "_lighter"] = lighten(Settings.colorChangeMD, color)
    Colors[name + "_lightest"] = lighten(Settings.colorChangeLG, color)
    Colors[name + "_dark"] = darken(Settings.colorChangeSM, color)
    Colors[name + "_darker"] = darken(Settings.colorChangeMD, color)
    Colors[name + "_darkest"] = darken(Settings.colorChangeLG, color)
});

export const Icons = {
    pit_logo: PitLogo
};
