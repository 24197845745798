import React, { Component } from "react"
import { Colors } from "../../../Theme"
import SVGIcon from "../../layout/SVGIcon"
import styled from "styled-components";

const Container = styled.div`
    width: 100%;
`

export default class MenuIcon extends Component {
    render() {
        const { ...props } = this.props
        return (
            <Container>
                <SVGIcon
                    path="M17,87.833h362.667c9.35,0,17-7.65,17-17s-7.65-17-17-17H17c-9.35,0-17,7.65-17,17C0,80.183,7.65,87.833,17,87.833z M17,215.333h362.667c9.35,0,17-7.65,17-17s-7.65-17-17-17H17c-9.35,0-17,7.65-17,17S7.65,215.333,17,215.333z M17,342.833h362.667c9.35,0,17-7.65,17-17s-7.65-17-17-17H17c-9.35,0-17,7.65-17,17S7.65,342.833,17,342.833z"
                    viewbox="0 0 396.667 396.667"
                    iconColor={Colors.white}
                    iconColorOnHover={Colors.gray_lighter}
                    {...props}
                />
            </Container>

        )
    }
}
