import { Colors } from "../../Theme"
import { get } from "idb-keyval"
import Checklist from "./Activity/Checklist"
import CloseIcon from "./../layout/Icons/Close"
import ConfirmButton from "./../layout/Buttons/Confirm"
import EscalatedIcon from "./../layout/Icons/Escalated"
import NotificationIcon from "./../layout/Icons/Notification"
import React, { Component } from "react"
import ReactHtmlParser from "react-html-parser"
import styled from "styled-components"
import TextInputField from "./../layout/TextInputField"
import UpdateActivityInPit from "./../pit/saveActivity"
import Pictures from "./Activity/Pictures"
import TextAreaField from "../layout/TextAreaField";
import {Col, Container, Row} from "react-bootstrap";

const Label = styled.div`
    color: ${Colors.text_lighter};
    padding: 0.5rem 0;
    font-size: 0.8rem;
`

const Description = styled.div`
    width: 100%;
    > p {
        margin: 0;
    }
`

export default class Activity extends Component {
    constructor(props) {
        super(props)
        this.state = {
            escalationError: false,
            role: this.getRole()
        }
        this.saveActivity = this.saveActivity.bind(this)
        this.valueOfTextfieldUpdated = this.valueOfTextfieldUpdated.bind(this)
        this.toggleNotification = this.toggleNotification.bind(this)
        this.toggleEscalated = this.toggleEscalated.bind(this)
        this.updateChecklist = this.updateChecklist.bind(this)
        this.escalationError = false
        this.state = {
            pictures:
                this.props.items !== undefined || this.props.items !== false
                    ? this.props.items
                    : []
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            activity: nextProps.activity
        }
    }

    saveActivity() {
        if (
            this.state.activity.escalated &&
            this.state.activity.escalationReason === ""
        ) {
            this.setState({
                escalationError: true
            })
            return
        }

        UpdateActivityInPit(this.state.activity).then(res => {
            this.props.updateNodes()
        })
    }

    toggleNotification() {
        const Activity = this.state.activity
        Activity.notification = !this.state.activity.notification
        this.setState({ activity: Activity })
        this.saveActivity()
    }

    toggleEscalated() {
        const Activity = this.state.activity
        Activity.escalated = !this.state.activity.escalated
        this.setState({ activity: Activity })
    }

    valueOfTextfieldUpdated(name, value) {
        const Activity = this.state.activity;
        Activity[name] = value;
        this.setState({ activity: Activity });
    }

    updateChecklist(checklist) {
        const Activity = this.state.activity
        Activity["checks"] = checklist
        this.setState({ activity: Activity })
    }

    async getRole() {
        await get("pit_role").then(val => {
            this.setState({ role: val })
        })
    }

    render() {
        return (
          <Container style={{padding:'1rem'}} fluid>
              <Row style={{minHeight:'4rem'}}>
                  <Col>
                      <Container style={{float:'right'}}>
                          <CloseIcon onClick={this.props.closeSidebar}  />
                      </Container>
                  </Col>
              </Row>
              <Row>
                  <Col>
                      <Label>Titel</Label>
                      <Description>{this.state.activity.title}</Description>
                  </Col>
              </Row>
              <Row>
                  <Col>
                      <Label>Beschrijving</Label>
                      <Description>{ReactHtmlParser(this.state.activity.description)}</Description>
                  </Col>
              </Row>
              {typeof this.state.activity.checks === "object" &&
                this.state.activity.checks.length > 0 && (
                <Row>
                    <Col>
                        <Checklist
                          items={this.state.activity.checks}
                          updateChecklist={this.updateChecklist}
                        />
                    </Col>
                </Row>
              )}
              {this.state.activity.pictures &&
                <Row>
                    <Pictures items={this.state.activity.pictures} />
                </Row>
              }
              <Row>
                  <Col>
                      <NotificationIcon
                        size={2}
                        active={this.state.activity.notification}
                        onClick={() => this.toggleNotification()}
                        visible={
                            this.state.role === "SiteAdministrator" ||
                            this.state.role === "Administrator"
                        }
                        label="Notificatie bij compleet"
                      />
                  </Col>
              </Row>
              <Row>
                  <Col>
                      <EscalatedIcon
                        size={2}
                        active={this.state.activity.escalated}
                        onClick={() => this.toggleEscalated()}
                        label="Escaleren"
                        background={Colors.orange_littlelighter}
                      />
                  </Col>
              </Row>
              <Row>
                  <Col>
                      <TextInputField
                        key="escalationReason"
                        name="escalationReason"
                        value={this.state.activity.escalationReason}
                        valueOfTextfieldUpdated={(value) => this.valueOfTextfieldUpdated("escalationReason", value)}
                        label="Escalatie reden"
                        visible={this.state.activity.escalated}
                        error={this.state.escalationError}
                      />
                  </Col>
              </Row>
              {!this.state.activity.escalated && <Row>
                  <Col>
                      <Label>Notitie</Label>
                      <TextAreaField
                        name="note"
                        value={this.state.activity.note}
                        valueOfTextfieldUpdated={(value) => this.valueOfTextfieldUpdated("note", value)}
                        visible={!this.state.activity.escalated}
                        backgroundColor={Colors.white}
                      />
                  </Col>
              </Row> }
              <Row style={{padding:'1rem'}}>
                  <ConfirmButton
                    label="Save"
                    width="80%"
                    onClick={() => this.saveActivity()}
                  />
              </Row>
          </Container>
            // <UContainer>
            //
            //
            //     <InfoContainer>
            //         <Description
            //             label="Title"
            //             content={this.state.activity.title}
            //         />
            //         <Description
            //             label="Description"
            //             content={ReactHtmlParser(this.state.activity.description)}
            //         />
            //     </InfoContainer>
            //     <UserFillContainer>
            //         {typeof this.state.activity.checks === "object" &&
            //             this.state.activity.checks.length > 0 && (
            //                 <Checklist
            //                     items={this.state.activity.checks}
            //                     updateChecklist={this.updateChecklist}
            //                 />
            //             )}
            //         <Pictures items={this.state.activity.pictures} />
            //             <NotificationIcon
            //                 size={2}
            //                 active={this.state.activity.notification}
            //                 onClick={() => this.toggleNotification()}
            //                 visible={
            //                     this.state.role === "SiteAdministrator" ||
            //                     this.state.role === "Administrator"
            //                 }
            //                 label="Notification"
            //             />
            //             <EscalatedIcon
            //                 size={2}
            //                 active={this.state.activity.escalated}
            //                 onClick={() => this.toggleEscalated()}
            //                 label="Escalation"
            //             />
            //         <TextInputField
            //             key="escalationReason"
            //             name="escalationReason"
            //             value={this.state.activity.escalationReason}
            //             valueOfTextfieldUpdated={(value) => this.valueOfTextfieldUpdated("escalationReason", value)}
            //             label="Escalation reason"
            //             visible={this.state.activity.escalated}
            //             error={this.state.escalationError}
            //         />
            //         <TextAreaField
            //             name="note"
            //             value={this.state.activity.note}
            //             valueOfTextfieldUpdated={(value) => this.valueOfTextfieldUpdated("note", value)}
            //             visible={!this.state.activity.escalated}
            //             label="Note"
            //         />
            //     </UserFillContainer>
            //     <ConfirmButton
            //         label="Save"
            //         width="80%"
            //         onClick={() => this.saveActivity()}
            //     />
            // </UContainer>
        )
    }
}
