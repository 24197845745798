import {GET} from "../../pit/Requests";
import {ENDPOINTS} from "../../pit/Endpoints";
import * as Yup from 'yup'

export const rules = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Te kort')
        .max(50, 'Te lang')
        .required('Verplicht'),
    lastName: Yup.string()
        .min(2, 'Te kort')
        .max(50, 'Te lang')
        .required('Verplicht'),
    email: Yup.string()
        .email('Ongeldig email adres')
        .required('Verplicht'),
    company: Yup.string()
        .min(2, 'Te kort')
        .max(50, 'Te lang')
        .required('Verplicht'),
    password: Yup.string()
        .min(6, 'Wachtwoord dient uit minimaal 6 karakters te bestaan')
        .required('Verplicht'),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password')], 'Komt niet overeen')
        .required('Verplicht'),
});

export const validateCompany = (value) => {
    let errors = {};

    return GET(ENDPOINTS.checkCompanyNameAvailability + "?company=" + value)
        .then(res => {

            if (!res.data.result.available) {
                errors.company = "Bedrijfsnaam in gebruik."
                throw errors.company;
            }
        });
};

export const validateEmail = (value) => {
    return GET(ENDPOINTS.checkMailAvailability + "?mail=" + value)
        .then(res => {

            if (!res.data.result.available) {
                let errors = [];
                errors.email = "Email adres in gebruik."
                throw errors.email;
            }
        });
};