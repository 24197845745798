import { Colors } from "../../Theme"
import React, { Component } from "react"
import styled from "styled-components"

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    background-color: ${props => props.backgroundColor};
    border-bottom: 2px solid ${Colors.background};

    &.singlePoint {
        background-color: transparent;
        color: ${Colors.text_lighter};
    }
    &:hover {
        cursor: pointer;
    }
`

const Content = styled.div`
    flex-grow: 2;
    justify-content: flex-start;

    font-size: 1rem;
    padding: 1rem 0;
    color: ${Colors.text_lighter};

    &.big {
        padding: 2rem 0;
        color: ${Colors.text};
    }
    &.biggest {
        padding: 2.5rem 0;
    }
`

const Small = styled.div`
    font-size: 0.8rem;
    color: ${Colors.text_lighter};
    padding: 0 1rem;
`

export default class ListItem extends Component {
    render() {
        const Urgency = this.props.urgency

        let Size = "normal"
        let BackgroundColor = Colors.white
        switch (Urgency) {
            case 1:
                Size = "biggest"
                BackgroundColor = Colors.white
                break
            case 2:
                Size = "big"
                BackgroundColor = Colors.white
                break
            default:
                Size = "normal"
                BackgroundColor = "transparent"
                break
        }
        return (
            <Container
                onClick={this.props.onClick}
                backgroundColor={BackgroundColor}
            >
                <Small>{this.props.small}</Small>
                <Content className={Size}>{this.props.content}</Content>
            </Container>
        )
    }
}
