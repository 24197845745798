import {Colors, Settings} from "./../../Theme"
import React, { Component } from "react"
import styled from "styled-components"

const Container = styled.div`
    box-shadow: ${Settings.box_shadow};
    display: ${props => (props.visible ? "block" : "none")};
    z-index: 2;
    left: 0;
    top: 0;
    width: 85%;
    height: 80%;
    overflow: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(255,255,255);
    background-color: rgba(255,255,255,0.95);
`

const OuterContainer = styled.div`
    display: ${props => (props.visible ? "block" : "none")};
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgb(155,155,155);
    background-color: rgba(0,0,0,0);
`

const Title = styled.div`
    padding: 0.5rem 1em 0.5rem 1rem;
    font-size: 1rem;
    color: ${Colors.text_light};
    font-weight: bold;
    border-bottom: 0.1rem solid #e8e8e8;
    text-transform: uppercase;
`

const GroupLabel = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 1.5em 0.5rem 1rem;
    font-weight: bold;
`

const TimelineLabel = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.2rem 1.5em 0.2rem 1.5rem;
    font-style: italic;
    font-size: 1rem;
    background:  ${props => (props.active ? Colors.blue : "none")};
    
    &:hover {
        background: ${Colors.hover};
        cursor: pointer;
    }
`


export default class TimelinePicker extends Component {
    render() {
        const {
            groups,
            timeLines,
            selectedTimelineId,
            changeTimeline,
            close
        } = this.props
        let Groups = []
        if (typeof timeLines === "object" && timeLines.length > 0) {

            var filteredTimeLinesByGroup = []
            for (const group of groups) {
                const groupTimelines = timeLines.filter(timeline => timeline.groupId === group.id);
                filteredTimeLinesByGroup.push({ ...group, timelines: groupTimelines });
            }

            filteredTimeLinesByGroup.forEach(Group => {
                let timelines = []

                if (
                    typeof Group.timelines === "object" &&
                    Group.timelines.length > 0
                ) {
                    Group.timelines.forEach(Timeline => {
                        timelines.push({
                            label: Timeline.location,
                            hover: false,
                            active: selectedTimelineId === Timeline.id,
                            onClick: () => changeTimeline(Timeline.id, Timeline.location, Group.name, Timeline.dayStartTime)
                        })
                    })
                    Groups.push({
                        label: Group.name,
                        subitems: timelines
                    })
                }

            })
        }


        return (
            <OuterContainer visible={this.props.visible} onClick={close}>
                <Container visible={this.props.visible}>
                    <Title>Timelines</Title>
                    {Groups.map((group, key) => (
                        <div key={'div' + key}>
                            <GroupLabel key={key}>
                                {group.label}

                            </GroupLabel>
                            {group.subitems.map((timeline, index) => (
                                <TimelineLabel key={key + index} onClick={timeline.onClick} active={timeline.active}>
                                    {timeline.label}
                                    {timeline.active ? (
                                        <div>&bull;</div>
                                    ) : null}
                                </TimelineLabel>
                            ))}
                        </div>
                    ))}

                </Container>
            </OuterContainer>

        )
    }
}
